export const formatFileSize = (originalFileSize: number): string => {
  const preciseFileSize = Math.ceil(originalFileSize / 1000000);
  if (preciseFileSize <= 1) {
    return '0-1 MB';
  }
  if (preciseFileSize <= 5) {
    return '1-5 MB';
  }
  if (preciseFileSize <= 10) {
    return '5-10 MB';
  }
  if (preciseFileSize <= 20) {
    return '10-20 MB';
  }
  if (preciseFileSize <= 30) {
    return '20-30 MB';
  }
  if (preciseFileSize <= 40) {
    return '30-40 MB';
  }
  if (preciseFileSize <= 51) {
    return '40-50 MB';
  }

  return preciseFileSize.toString();
};
