export const isLocalStorageAvailable = (): boolean => {
  try {
    const testKey = '_test_local_storage_';
    localStorage.setItem(testKey, 'true');
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};
