import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LinkProps } from 'ts/types/link.props';

export const LinkContainer = styled(Link)<LinkProps>`
  /* Desktop/Body/Reg */

  font-size: ${(props) => props.size || '18px'};
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  cursor: pointer;
  text-decoration: none;
  text-underline-offset: 4px;
  width: fit-content;

  color: ${(props) => {
    if (props?.disabled || !props?.type) return '#6B7280';
    switch (props?.type) {
      case 'primary':
        return '#3758F9';
      case 'secondary':
        return '#EBEEFF';
      case 'tertiary':
        return '#4C5E7F';
      case 'primary-contrast':
        return '#2443D7';
      default:
        return '#4C5E7F';
    }
  }};

  &:hover {
    opacity: 1;
    text-decoration: underline;

    color: ${(props) => {
      if (props?.disabled || !props?.type) return '#6B7280';
      switch (props?.type) {
        case 'primary':
          return '#2443D7';
        case 'secondary':
          return '#FFF';
        case 'tertiary':
          return '#212E45';
        default:
          return '#212E45';
      }
    }};
  }

  ${(props) => props.disabled && 'pointer-events: none;'}
`;
