import { FC } from 'react';

//** Components */
import EnterEmailAddressModal from './index';
import EnterEmailModalTestB from './tests/emailModalTestB';
import EnterEmailModalTestC from './tests/emailModalTestC';

import { LocalStorageProperties } from 'ts/enums/growthbook';
import { useEmailModalTestValue } from 'hooks/growthBook/useEmailModalTestValue';

const pricingPagesDictionary: any = {
  [`${LocalStorageProperties.SEND_FILE_MODAL_TEST_A}`]: <EnterEmailAddressModal />,
  [`${LocalStorageProperties.SEND_FILE_MODAL_TEST_B}`]: <EnterEmailModalTestB />,
  [`${LocalStorageProperties.SEND_FILE_MODAL_TEST_C}`]: <EnterEmailModalTestC />,
};

const EmailModalTestABC: FC = () => {
  const testValue = useEmailModalTestValue();

  return pricingPagesDictionary[testValue as string] || <EnterEmailAddressModal />;
};

export default EmailModalTestABC;
