export enum EServiceType {
  EDITOR = 'EDITOR',
  CONVERTOR = 'CONVERTOR',
  COMPRESSOR = 'COMPRESSOR',
  PDF_CONVERTOR = 'PDF_CONVERTOR',
  SPLITTER = 'SPLITTER',
  MERGER = 'MERGER',
  OCR = 'OCR',
}

export interface IService {
  metaTitle?: string;
  serviceType: EServiceType;
  metaDescription?: string;
  nameKey: string;
  path: string;
  name: string;
  title: string;
  description: string;
  icon?: string;
  from: string;
  to: string;
  availableFormats: string;
  aboutSection?: {
    title: string;
    items: string[];
  };
  ctaTitle?: string;
  meta?: {
    title?: string;
    description?: string;
  };
  heroFeatures?: {
    title: string;
    description?: string;
  }[];
}
