import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 44px 20px 30px 20px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 470px;
  min-height: 213px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
    padding: 20px 35px;
  }

  button {
    margin-top: 30px;
  }
`;

export const Title = styled(CommonTextH5)`
  margin: 15px 0;
  text-align: center;
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Description = styled.div`
  color: var(--Colors-black-grey-white-grey-60, #616161);
  text-align: center;
  /* Desktop/Body/Reg */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
`;

export const Email = styled.div`
  color: var(--Grey-90, #354562);
  /* Body/Bold */

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
