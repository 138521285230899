import { useEffect } from 'react';
import queryString from 'query-string';
import get from 'lodash/get';

interface Options {
  [key: string]: string | Date | number | boolean;
}

export const getCookie = (name: string) => {
  try {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`)
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  } catch (err) {
    return undefined;
  }
};

export const getCookieDataByDestination = (destination: string) => {
  const cookieData = getCookie(destination);

  if (!cookieData) return cookieData;
  try {
    const parsedData = JSON.parse(`${cookieData}`);
    return get(parsedData, `${destination}`);
  } catch (e) {
    return false;
  }
};

export const getQueryData = () => queryString.parse(window.location.search);

export const getQueryParams = () => {
  const params = new Map();
  const dictionary = [
    { source: 'email', destination: 'relationEmail' },
    { source: 'affiliate', destination: 'queryAffiliate' },
    { source: 'relationToken', destination: 'relationToken' },
  ];

  const queryItems = queryString.parse(window.location.search);

  dictionary.forEach((item) => {
    const queryItem =
      item.source === 'email'
        ? get(queryItems, `${item.source}`, localStorage.getItem(item.destination))
        : getCookieDataByDestination(item.destination) || get(queryItems, `${item.source}`);
    if (queryItem) {
      params.set(item.destination, queryItem);
    }
  });
  return params;
};

export const useSetSignUpQuery = () => {
  const params = getQueryParams();

  useEffect(() => {
    if (params?.size) {
      params.forEach((item, key) => {
        if (key === 'relationEmail') localStorage.setItem(key, item);
      });
    }
  }, [params]);
};

export const setCookie = (name: string, value: string, options: Options = {}) => {
  try {
    const updatedOptions: Options = {
      path: '/',
      ...options,
    };

    if (updatedOptions.expires instanceof Date) {
      updatedOptions.expires = updatedOptions.expires.toUTCString();
    }

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    Object.keys(updatedOptions).forEach((optionKey) => {
      updatedCookie += `; ${optionKey}`;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    });

    document.cookie = updatedCookie;
    return true;
  } catch (err) {
    return false;
  }
};
