import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { toggleModal } from 'data/actions/modals';
import { EModalTypes } from 'ts/enums/modal.types';

const useResetPassword = () => {
  const dispatch = useDispatch();

  if (window?.location?.pathname !== '/reset-password') return null;
  const parsed = queryString.parse(window.location.search);

  if (parsed?.token) {
    setTimeout(() => {
      dispatch(
        toggleModal({
          type: EModalTypes.RECOVER_PASSWORD,
          visible: true,
          options: { token: parsed?.token },
        })
      );
    }, 0);
  }
};

export default useResetPassword;
