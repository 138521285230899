export enum InternalFileType {
  DOC = 'DOC',
  DOCX = 'DOCX',
  JPEG = 'JPEG',
  JPG = 'JPG',
  HEIC = 'HEIC',
  HEIF = 'HEIF',
  PDF = 'PDF',
  PNG = 'PNG',
  PPT = 'PPT',
  PPTX = 'PPTX',
  XLS = 'XLS',
  XLSX = 'XLSX',
  ZIP = 'ZIP',
  BMP = 'BMP',
  EPS = 'EPS',
  GIF = 'GIF',
  SVG = 'SVG',
  TIFF = 'TIFF',
  WEBP = 'WEBP',
  EPUB = 'EPUB',
  HTML = 'HTML',
  TEXT = 'TEXT',
  MOBI = 'MOBI',
  AZW3 = 'AZW3',
  DWG = 'DWG',
  CSV = 'CSV',
  DXF = 'DXF',
  DJVU = 'DJVU',
}

export interface ApiFile {
  id: string;
  size: number;
  filename: string;
  created_at: Date;
  aws_url: string;
  internal_type: InternalFileType;
}
