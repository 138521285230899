import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(11, 16, 44, 0.3);
  z-index: 1000;
  align-items: center;
  align-self: center;
  justify-content: center;
  padding: 0 15px;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;

  .ant-upload-drag {
    width: 100vw !important;
    height: 100vh !important;
    border: none !important;
    z-index: 999 !important;
  }

  .ant-upload-btn {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 760px) {
    // trick to center a modal
    height: calc(100vh - calc(100vh - 100%));
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;
