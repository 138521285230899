import styled from 'styled-components';

export const ErrorIcon = styled.img`
  color: red;

  @media (max-width: 760px) {
    width: 48px;
    height: 48px;
  }

  @media (max-width: 560px) {
    width: 32px;
    height: 32px;
  }
`;

export const SuccessIcon = styled.img`
  color: green;

  @media (max-width: 760px) {
    width: 48px;
    height: 48px;
  }

  @media (max-width: 560px) {
    width: 32px;
    height: 32px;
  }
`;

export const InfoIcon = styled.img`
  @media (max-width: 760px) {
    width: 48px;
    height: 48px;
  }

  @media (max-width: 560px) {
    width: 32px;
    height: 32px;
  }
`;

export const CloseButton = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 25%;
  will-change: transform;
  transition: transform 200ms ease;

  &:hover,
  &:focus {
    background: rgb(245, 247, 248);
    transition: all 0.2s ease-in-out 0s;
  }

  &:active {
    transform: scale(0.8);
  }

  font-size: 30px;

  img {
    width: 16px;
    height: 16px;
  }
`;
