import { ILoadingState } from 'ts/interfaces/loading/loading';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: ILoadingState = {
  sign_up: false,
  sign_in: false,
  confirm_account: false,
  password_reset: false,
  password_forgot: false,
  google_auth: false,
  get_user: true,
  update_user: false,
  get_documents: true,
  save_edit_document: false,
};

const loadingReducer = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case EReduxTypes.UPDATE_LOADING_COMPONENT: {
      const { components, loading } = action.data;

      components.forEach((item: keyof ILoadingState) => {
        state[item] = loading;
      });

      return {
        ...state,
      };
    }

    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default loadingReducer;
