import { isBrowser } from 'utils/isBrowser';

export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  Unknown = 'unknown',
}

export const getDeviceType = (): DeviceType => {
  if (isBrowser) {
    const device = require('current-device').default;
    return device.type as DeviceType;
  }

  return DeviceType.Unknown;
};

export const isIPad = (): boolean => {
  if (isBrowser) {
    const device = require('current-device').default;
    return device.ipad();
  }

  return false;
};
