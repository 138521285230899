import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 44px 50px 20px 50px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
    height: auto;
  }
`;

export const FinalStepContainer = styled(Container)`
  padding: 40px 50px 40px 50px;
  min-width: 343px;
  max-width: 550px;
  width: 100%;
  position: relative;
  gap: var(--Radius-l, 20px);

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
    padding: 20px;
    max-height: fit-content;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
`;

export const OwlImage = styled.img`
  width: 144.068px;
  height: 144.38px;
`;

export const Title = styled(CommonTextH5)`
  color: var(--Text-color-tittle, #212e45);
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 32.5px */
`;
