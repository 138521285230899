import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import rocketImgPath from 'assets/subscription-discount-rocket.svg';
import { ModalContainer, CloseIcon } from '../baseModal/styles';
import { Description, Container, RocketImage } from './styles';
import { toggleModal } from 'data/actions/modals';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import useDisableScroll from 'hooks/useDisableScroll';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import { sendAnalyticEvent } from 'data/actions/analytics';

const ThankSubscriptionOfferModal: FC = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  useDisableScroll();
  useCloseModalOnBack();

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'thank_subscription_offer_modal_view' }));
  }, [dispatch]);

  const handleClose = () => {
    dispatch(toggleModal({ visible: false }));
    navigate('/app/settings');
  };

  return (
    <ModalContainer>
      <Container>
        <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
        <RocketImage src={rocketImgPath} alt="rocket" />
        <Description>
          {t('thank_subscription_offer_modal.title')}
          <br />
          {t('thank_subscription_offer_modal.description')}
        </Description>
      </Container>
    </ModalContainer>
  );
};

export default ThankSubscriptionOfferModal;
