import fileDocImgPath from 'assets/fileTypes/file-doc-icon.svg';
import fileDocxImgPath from 'assets/fileTypes/file-docx-icon.svg';
import {
  default as fakeFileImgPath,
  default as filePdfImgPath,
} from 'assets/fileTypes/file-pdf-icon.png';
import filePptImgPath from 'assets/fileTypes/file-ppt-icon.svg';
import filePptxImgPath from 'assets/fileTypes/file-pptx-icon.svg';
import fileTxtImgPath from 'assets/fileTypes/file-txt-icon.svg';
import fileXlsImgPath from 'assets/fileTypes/file-xls-icon.svg';
import fileXlsxImgPath from 'assets/fileTypes/file-xlsx-icon.svg';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { InternalFileType } from 'helpers/internalFileTypes';
import { ComponentProps, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PreviewStatusView } from 'services/analytics/events';

export const useRenderFileImage = ({
  imagePDF,
  imageCover,
  isImageLoading,
  formatFrom,
  formatTo,
  FilePreview,
}: any) => {
  const dispatch = useDispatch();
  const sendPreviewStatusViewEvent = useCallback((status: PreviewStatusView['data']['status']) => {
    dispatch(sendAnalyticEvent({
      event: 'preview_status_view',
      data: {
        status,
      },
    }));
  }, [dispatch]);

  const previewEventsProps = useCallback<
    (successStatus: PreviewStatusView['data']['status']) => Partial<ComponentProps<'img'>>
  >(
    (successStatus = 'success') => ({
      onLoad: () => sendPreviewStatusViewEvent(successStatus),
      onError: () => sendPreviewStatusViewEvent('error'),
    }),
    [sendPreviewStatusViewEvent]
  );

  const renderFileImage = useCallback(() => {
    try {
      if (isImageLoading) return null;
      const formatToDisplay =
        [
          InternalFileType.PDF,
          InternalFileType.JPEG,
          InternalFileType.JPG,
          InternalFileType.PNG,
        ].includes(formatFrom) && formatTo !== InternalFileType.TXT
          ? formatFrom
          : formatTo;
      switch (formatToDisplay) {
        case InternalFileType.PDF: {
          if (imagePDF)
            return (
              <FilePreview
                alt="file_img"
                src={URL.createObjectURL(imagePDF)}
                {...previewEventsProps('success')}
              />
            );
          return null;
        }
        case InternalFileType.TXT: {
          return (
            <FilePreview src={fileTxtImgPath} {...previewEventsProps('success')} alt="fake_file" />
          );
        }
        case InternalFileType.JPEG:
        case InternalFileType.JPG:
        case InternalFileType.PNG: {
          if (imageCover)
            return (
              <FilePreview
                alt="file_img"
                src={imageCover}
                {...previewEventsProps('success')}
                onError={(event: any) => {
                  event.target.onerror = null; // prevents looping
                  event.target.src = filePdfImgPath;
                  sendPreviewStatusViewEvent('error');
                }}
              />
            );
          return null;
        }
        case InternalFileType.DOCX: {
          return (
            <FilePreview alt="file_img" src={fileDocxImgPath} {...previewEventsProps('success')} />
          );
        }
        case InternalFileType.DOC: {
          return (
            <FilePreview alt="file_img" src={fileDocImgPath} {...previewEventsProps('success')} />
          );
        }
        case InternalFileType.XLS: {
          return (
            <FilePreview alt="file_img" src={fileXlsImgPath} {...previewEventsProps('success')} />
          );
        }
        case InternalFileType.XLSX: {
          return (
            <FilePreview alt="file_img" src={fileXlsxImgPath} {...previewEventsProps('success')} />
          );
        }
        case InternalFileType.PPT: {
          return (
            <FilePreview alt="file_img" src={filePptImgPath} {...previewEventsProps('success')} />
          );
        }
        case InternalFileType.PPTX: {
          return (
            <FilePreview alt="file_img" src={filePptxImgPath} {...previewEventsProps('success')} />
          );
        }
        default:
          return <FilePreview src={fakeFileImgPath} alt="fake_file" />;
      }
    } catch (err) {
      return (
        <FilePreview src={fakeFileImgPath} alt="fake_file" {...previewEventsProps('impossible')} />
      );
    }
  }, [
    isImageLoading,
    formatFrom,
    formatTo,
    FilePreview,
    imagePDF,
    previewEventsProps,
    imageCover,
    sendPreviewStatusViewEvent,
  ]);

  return useMemo(() => renderFileImage(), [renderFileImage]);
};
