import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 30px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 410px;
  min-height: 281px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
    padding: 20px 35px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Grey-80, #354562);
  margin-bottom: 15px;
  text-align: center;
`;

export const Image = styled.img`
  width: 100px;
  height: 98.028px;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 35px;
`;

export const Description = styled.div`
  color: var(--Grey-90, #354562);
  text-align: center;
  /* Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

export const Email = styled.div`
  color: var(--Grey-90, #354562);
  /* Body/Bold */

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
