import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

const UNKNOWN_FEATURE = 'unknownFeature';

export const useModalVsUnitedPayment = () => {
  const abTests = getJsonFomStorage('abTests');
  const testingModalVsUnitedPaymentABC = useFeature(GrowthBookTestType.MODAL_VS_UNITED_PAYMENT);

  if (
    testingModalVsUnitedPaymentABC?.off === true &&
    testingModalVsUnitedPaymentABC.source !== UNKNOWN_FEATURE
  )
    return LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A as const;

  if (abTests?.includes(LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B))
    return LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B as const;
  else if (abTests?.includes(LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_C))
    return LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_C as const;

  if (
    testingModalVsUnitedPaymentABC?.experiment?.key &&
    testingModalVsUnitedPaymentABC?.experimentResult?.value
  ) {
    return `${testingModalVsUnitedPaymentABC?.experiment?.key}_${testingModalVsUnitedPaymentABC?.experimentResult?.value}` as
      | LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A
      | LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B
      | LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_C;
  }

  return LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A as const;
};
