import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { AnyAction, Dispatch } from 'redux';
import { Analytics, AnalyticsUserProp } from 'services/analytics';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { getMarketingValues } from 'utils/storeMarketingValues';
import { updateUserMeta } from 'utils/updateUserMeta';

interface IChangeUserPassword {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export const getUserCountry = (onSuccess?: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USER_COUNTRY,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/user/country',
    },
    onSuccess,
    components: ['update_user'],
  };
  return action;
};

export const getUser = ({
  onFailed,
  onSuccess,
}: {
  onFailed?: unknown;
  onSuccess?: unknown;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_USER,
    payload: {
      method: 'GET',
      endpoint: 'api/v1/user/me',
    },
    components: ['get_user'],
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    onSuccess: async (res: any) => {
      const abTests = getJsonFomStorage('abTests');

      Analytics.setUserId(res?.id);
      await updateUserMeta();

      const userData: AnalyticsUserProp[] = [{ key: 'is_premium', value: !!res?.subscription }];

      if (res?.email) userData.push({ key: 'email', value: res?.email });

      if (res?.ab_test || abTests?.length) {
        userData.push({
          key: 'ab_test',
          value: res?.ab_test?.length ? res?.ab_test : abTests,
        });
      }

      const marketingValues = getMarketingValues();
      if (marketingValues) {
        for (const [key, value] of Object.entries(marketingValues)) {
          userData.push({ key, value });
        }
      }

      Analytics.updateUser(userData);
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};

export const createAnonymousUser = (onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CREATE_ANONYMOUS_USER,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/user/create',
    },
    components: ['get_user'],
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
  };
  return action;
};

export const createUserReveal = ({
  email,
  onSuccess,
  onFailed,
  sendFile,
}: {
  email: string;
  onSuccess?: unknown;
  onFailed?: unknown;
  sendFile?: boolean;
}): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CREATE_ANONYMOUS_USER,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/user/reveal',
      body: {
        email,
        sendFile,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
  };
  return action;
};

export const signUp = (
  email: string,
  password: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_UP,
    payload: {
      method: 'POST',
      endpoint: `api/v1/auth/signup-with-email`,
      body: {
        email,
        password,
      },
    },
    components: ['sign_up'],
    skipAuth: true,
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Create account error';
      if (typeof onFailed === 'function') onFailed(errorMessage);
    },
  };
  return action;
};

export const signIn = (
  email: string,
  password: string,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  Analytics.sendEvent({ event: 'login_confirm_tap', data: { type: 'email' } });
  const action: IReduxAction = {
    type: EReduxTypes.SIGN_IN,
    payload: {
      method: 'POST',
      endpoint: `api/v1/auth/login`,
      body: {
        email,
        password,
        rememberMe: true,
      },
    },
    components: ['sign_in'],
    skipAuth: true,
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Sign-in error';
      if (typeof onFailed === 'function') onFailed(errorMessage);
    },
  };
  return action;
};
export const signOut = (onSuccess?: unknown, onFailed?: unknown) => (dispatch: Dispatch): void => {
  Analytics.sendEvent({ event: 'log_out_tap' });
  localStorage.removeItem('dataToConvert');
  localStorage.removeItem('dataToEdit');
  localStorage.removeItem('abTests');

  const action: IReduxAction = {
    type: EReduxTypes.SIGN_OUT,
    payload: {
      method: 'POST',
      endpoint: `api/v1/auth/logout`,
      body: {},
    },
    components: ['sign_out'],
    skipAuth: true,
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Sign-out error';
      if (typeof onFailed === 'function') onFailed(errorMessage);
    },
  }

  dispatch(action);
};

export const changeUserPassword = (
  { currentPassword, newPassword, repeatPassword }: IChangeUserPassword,
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CHANGE_USER_PASSWORD,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/user/change-password',
      body: {
        currentPassword,
        newPassword,
        repeatPassword,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    components: ['update_user'],
  };
  return action;
};

export const changeUserEmail = (
  { newEmail }: { newEmail: string },
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.CHANGE_USER_EMAIL,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/user/change-email',
      body: {
        newEmail,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
    components: ['update_user'],
  };
  return action;
};

export const setUserEmail = (newEmail: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_USER_EMAIL,
    data: {
      newEmail,
    },
  };
  return action;
};

export const sendForgotPassword = (
  email: string,
  setSent: any = () => {},
  setError: any = () => {}
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_FORGOT_PASSWORD,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/auth/recover-password-request',
      body: {
        email,
      },
    },
    components: ['password_forgot'],
    onSuccess: () => {
      if (setSent) setSent();
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Password reset error';
      setError(errorMessage);
    },
  };
  return action;
};

export const googleAuth = (token: string, onSuccess?: unknown, setError?: any): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GOOGLE_AUTH,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/auth/google/sign-in',
      body: {
        token,
      },
    },
    components: ['google_auth'],
    skipAuth: true,
    onSuccess: (res: any) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onFailed: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Sign-in error';
      if (setError) setError(errorMessage);
    },
  };
  return action;
};

export const recoverPasswordPassword = (
  { password, repeatPassword, token }: { password: string; repeatPassword: string; token: string },
  onSuccess?: unknown,
  onFailed?: unknown
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SEND_FORGOT_PASSWORD,
    payload: {
      method: 'PATCH',
      endpoint: 'api/v1/auth/recover-password-confirmation',
      body: {
        password,
        repeatPassword,
        token,
      },
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onFailed: () => {
      if (typeof onFailed === 'function') onFailed();
    },
  };
  return action;
};

export const setPageView = (page: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_PAGE_VIEW,
    data: page,
  };
  return action;
};

export const getTrustPilotLink = (onSuccess?: unknown): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.GET_TRUST_PILOT_LINK,
    payload: {
      method: 'POST',
      endpoint: 'api/v1/trustpilot/invitation-link ',
    },
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
  };
  return action;
};
