import styled from 'styled-components';

export const Container = styled.div`
  width: 570px;
  height: 334px;
  border-radius: 20px;
  padding: 50px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;

  .ant-progress-inner {
    height: 20px;
    background: var(--Primary-10, #ebeeff);

    .ant-progress-bg {
      height: 20px !important;
      background: #3758f9 !important;
    }
  }

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
  }

  @media (max-width: 560px) {
    padding: 50px 20px;
  }
`;

export const Title = styled.span`
  color: var(--Dark-Dark, #111928);

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */

  @media (max-width: 560px) {
    font-size: 16px;
  }
`;

export const ProgressValue = styled.span`
  letter-spacing: 0.04em;
  font-variant-numeric: tabular-nums;
  color: var(--Blue-50, #3758f9);
  /* Desktop/Heading/H4 */

  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 29.9px */
  margin-left: 10px;

  @media (max-width: 560px) {
    font-size: 19px;
  }
`;

export const ProgressCOntainer = styled.span`
  display: flex;

  @media (max-width: 560px) {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }
`;

export const UploadImage = styled.img``;

export const UploadImageContainer = styled.div`
  display: flex;
  width: 112px;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Radius-s, 10px);
  border-radius: var(--Radius-xxl, 100px);
  background: var(--Blue-white, #fff);
  /* shadow */
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
`;
