import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { modalOptionsSelector } from 'data/selectors/modals';
import ModalLayout from 'components/modals/baseModal';
import SignIn from './sign-in';
import SignUp from './sign-up';
import ForgotPassword from './forgot-password';

import { AuthFormStep } from './types.d';

const AuthForm: FC = () => {
  const { signUp } = useSelector(modalOptionsSelector);
  const [currentStep, setCurrentStep] = useState<AuthFormStep>(
    AuthFormStep[signUp ? 'sign-up' : 'sign-in']
  );

  const renderAuthForm = (step: AuthFormStep) => {
    switch (step) {
      case AuthFormStep['sign-in']: {
        return <SignIn setStep={setCurrentStep} />;
      }
      case AuthFormStep['forgot-password']: {
        return <ForgotPassword />;
      }
      default: {
        return <SignUp setStep={setCurrentStep} />;
      }
    }
  };

  return <ModalLayout>{renderAuthForm(currentStep)}</ModalLayout>;
};

export default AuthForm;
