import { useState, useEffect } from 'react';

export const useAnimatedProgress = (durationInSeconds: number): number => {
  const durationInMilliseconds = durationInSeconds * 1000;
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    let startTime: number | null = null;
    let requestId: number;

    const animate = (timestamp: number) => {
      if (!startTime) {
        startTime = timestamp;
      }
      const timeElapsed = timestamp - startTime;
      setProgress(Math.min(1, timeElapsed / durationInMilliseconds));

      if (timeElapsed < durationInMilliseconds) {
        requestId = requestAnimationFrame(animate);
      }
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, []); // eslint-disable-line

  return progress;
};

export const calculateProgress = (time: number, min: number, max: number): number =>
  (max - min) * time + min;
