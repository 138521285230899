import { FC, ReactElement, useState } from 'react';

import ReasonsStep from './steps/reasons';
import FinalStep from './steps/final';
import ModalLayout from 'components/modals/baseModal';

const CancelPlanModal: FC = () => {
  const [currentStep, setCurrentStep] = useState('reasons');

  const renderStep = (step: string): ReactElement => {
    if (step === 'final') return <FinalStep />;
    return <ReasonsStep setStep={setCurrentStep} />;
  };

  return <ModalLayout>{renderStep(currentStep)}</ModalLayout>;
};

export default CancelPlanModal;
