import GooglePayIcon from 'assets/google-pay.svg';
import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 50%;
  position: relative;
  padding-right: 15px;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #paypal-button {
    div {
      animation-name: fadeInOpacity;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;
    }
  }

  .gpay-button-fill {
    height: 48px;
    border-radius: 5px;
    box-shadow: none;
    background: var(--Primary-white, #fff);

    button {
      background-image: url(${GooglePayIcon}) !important;
      height: 20px;
      min-width: 88px;
      background-color: #fff;
      padding: 13px 16px !important;
      box-shadow: none;
      border: 1px solid #dfe4ea;
    }
  }
`;

export const PaymentFormWrapper = styled.div`
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  div {
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }
`;

export const FormHeader = styled(CommonTextH5)`
  color: var(--Text-color-tittle, #212e45);
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div<{ is_test?: string }>`
  display: flex;
  margin: 20px 0;
  justify-content: space-between;

  @media (max-width: 760px) {
    justify-content: center;
    margin: ${({ is_test }) => (is_test === 'true' ? '20px 0 ' : '30px 15px')};
  }
`;

export const PaymentMethodButton = styled.button`
  flex: 0 0 48%;
  max-width: 247px;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 8px;
  background: none;
  outline: none;
`;

export const InputGroupSmall = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px var(--Spacing-sytem-spacing-l, 24px);

  @media (max-width: 760px) {
    flex-direction: column;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 15px;
    left: 0;
    background: #fff;
    gap: 10px;
    z-index: 200;
  }
`;

export const MobilePriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: none;

  @media (max-width: 760px) {
    display: flex;
  }
`;

export const MobileText = styled.div`
  color: var(--Grey-80, #354562);
  /* Desktop/Body/Bold */

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 23.4px */
  display: flex;
  align-items: center;
`;

export const MobilePriceText = styled.div`
  color: var(--Grey-80, #354562);

  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28px */
`;

export const PaymentStyles = {
  card_brands: {
    position: 'absolute',
    right: '0',
    gap: '10px',

    i: {
      width: '24px',
      height: '24px',
    },
  },
  submit_button: {
    'max-width': '430px',
    margin: '20px auto 20px',
    'background-color': '#14857A',
    display: 'none',
  },
  input_group: {
    'max-width': '430px',
    color: '#4C5E7F',
    'line-height': '130%',
    'font-weight': '400',
    'font-size': '16px',
    'font-style': 'normal',
    input: {
      height: '50px',
      display: 'flex',
      padding: '12px 16px 12px 20px',
      'align-items': 'center',
      'border-radius': '6px',
      border: '1px solid #DFE4EA',
      background: '#FFF',
      'font-size': '16px',
      'margin-top': '10px',

      ':active': {
        'outline-color': 'none',
        border: '1px solid #DFE4EA',
      },
      ':focus': {
        'outline-color': 'none',
        border: '1px solid #DFE4EA',
      },

      '::placeholder': {
        color: '#212E45',
        'font-weight': '400',
        'font-size': '16px',
        'font-style': 'normal',
        'line-height': '130%',
      },
    },
    '.label': {
      'line-height': '130%',
      color: '#4C5E7F',
      'font-weight': '400',
      'font-size': '16px',
      'font-style': 'normal',
    },

    '.brand-icon': {},

    '.tooltip-icon': {
      display: 'none',
    },

    '@media (max-width: 760px)': {
      background: 'red',
    },
  },

  'two-columns': {
    margin: '24px 0 30px 0',
  },
};

export const LabeledDivider = styled.div<{ is_test?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin: 0 10px;
    color: var(--TailGrids-Text-Color, #4c5e7f);
    text-align: center;
    /* Body/Med */

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }

  &::before,
  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background: #dfe4ea;
  }
`;
