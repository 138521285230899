import { useCallback, useEffect } from 'react';

const useKeyDownPress = ({
  targetKey,
  onKeyPressDown,
}: {
  targetKey: string;
  onKeyPressDown?: unknown;
}): void => {
  const downHandler = useCallback(
    (params: any) => {
      if (params.key === targetKey && typeof onKeyPressDown === 'function') {
        onKeyPressDown();
      }
    },
    [onKeyPressDown, targetKey]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};

export default useKeyDownPress;
