import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { toggleModal } from 'data/actions/modals';
import imagePath from 'assets/modal/own-with-background.svg';
import closeImagePath from 'assets/close-icon.svg';
import { FinalStepContainer, Title, OwlImage } from '../styles';
import { CloseIcon } from '../../baseModal/styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const FinalStep: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(sendAnalyticEvent({
      event: 'already_used_free_access_modal_view',
    }));
  }, [dispatch]);

  const handleClose = () => {
    dispatch(sendAnalyticEvent({
      event: 'already_used_free_access_close_tap',
    }));
    dispatch(toggleModal({ visible: false }));
  };

  return (
    <FinalStepContainer>
      <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
      <OwlImage alt="" src={imagePath} />
      <Title>{t('free_access_modal.error_title')}</Title>
    </FinalStepContainer>
  );
};

export default FinalStep;
