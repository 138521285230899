import React, { useCallback, useState } from 'react';

import { generatePDFCover } from './generatePdfCover';
import { ApiFile, InternalFileType } from 'ts/types/file';

interface IProps {
  file: ApiFile | undefined;
  fileUrl: string;
}

interface ILoadDocumentCover {
  imagePDF: Blob | null;
  imageCover: string | null;
  isImageLoading: boolean;
}

export const imagesFormat = [
  InternalFileType.HEIC,
  InternalFileType.SVG,
  InternalFileType.PNG,
  InternalFileType.BMP,
  InternalFileType.EPS,
  InternalFileType.GIF,
  InternalFileType.TIFF,
  InternalFileType.WEBP,
  InternalFileType.JPG,
  InternalFileType.JPEG,
  InternalFileType.EPS,
];

export const useLoadDocumentCover = (props: IProps): ILoadDocumentCover => {
  const { file, fileUrl } = props;
  const [imagePDF, setImagePDF] = useState<Blob | null>(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageCover, setImageCover] = useState<string | null>(null);

  const loadPdfCover = useCallback(async (): Promise<void> => {
    if (!file || !fileUrl || file?.internal_type !== InternalFileType.PDF) {
      return;
    }

    setIsImageLoading(true);

    try {
      const pdfCover = await generatePDFCover({
        pdfFileUrl: fileUrl,
        width: 640,
      });

      setImagePDF(pdfCover);
    } finally {
      setIsImageLoading(false);
    }
  }, [file, fileUrl]);

  const loadImageCover = React.useCallback(async () => {
    if (!file || !imagesFormat.includes(file?.internal_type) || !fileUrl) {
      return;
    }

    setImageCover(fileUrl);
  }, [file, fileUrl]);

  React.useEffect(() => {
    loadPdfCover();
    loadImageCover();
  }, [loadImageCover, loadPdfCover]);

  return {
    imagePDF,
    imageCover,
    isImageLoading,
  };
};
