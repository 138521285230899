import { Analytics } from 'services/analytics';
import { getPrevPage } from 'utils/prevPath';

export interface IHandleOnFail {
  paymentMethod: string;
  error_code: string;
  productId: string;
  error_message: string;
  orderId?: string;
}

export const handleOnFormMounted = (paymentMethod: string, isSafari: boolean) => {
  if (paymentMethod === 'googlebtn' && isSafari) {
    return;
  }

  if (paymentMethod === 'applebtn' && !isSafari) {
    return;
  }

  Analytics.sendEvent({
    event: 'payment_form_view',
    data: {
      paymentMethod: paymentMethod === 'form' ? 'Card' : paymentMethod,
    },
  });
};

export const handleOnSubmit = (paymentMethod: string, productId: string) => {
  void Analytics.sendEvent({
    event: 'continue_solid_tap',
    data: {
      productId,
      paymentMethod: paymentMethod === 'form' ? 'Card' : paymentMethod,
    },
  });
};

export const handleOnSuccessEvent = ({
  paymentMethod,
  orderId,
  productId,
  fullPrice,
  price,
}: {
  paymentMethod: string;
  orderId: string;
  productId: string;
  fullPrice?: number;
  price?: number;
}) => {
  void Analytics.sendEvent({
    event: 'sale_confirmation_success',
    data: {
      orderId,
      productId,
      paymentMethod: paymentMethod === 'form' ? 'Card' : paymentMethod,
      price: fullPrice,
      value: price,
      fromPage: getPrevPage() || '',
    },
  });
};

export const handleOnFail = (
  event: any,
  paymentMethod: string,
  productId: string,
  handleShowError: unknown
) => {
  const errorMessage = Array.isArray(event.message) ? event.message?.join(',') : event.message;

  const data: IHandleOnFail = {
    paymentMethod: paymentMethod === 'form' ? 'Card' : paymentMethod,
    orderId: event?.order?.order_id,
    error_code: event?.code,
    productId,
    error_message: errorMessage,
  };

  if (typeof handleShowError === 'function') handleShowError(event?.code, errorMessage);

  if (event?.order?.order_id) data.orderId = event?.order?.order_id;
  void Analytics.sendEvent({
    event: 'sales_confirmation_fail',
    data,
  });
};
