import { sendAnalyticEvent } from 'data/actions/analytics';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const usePaypalScript = ({
  paypalScript,
  signature,
  height,
}: {
  paypalScript: string | null;
  signature: string | undefined;
  height?: string;
}): void => {
  const [script, setScript] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendAnalyticEvent({
      event: 'paypal_script_init_fired',
      data: {
        paypalScript,
        signature,
      },
    }));

    if (signature && paypalScript && !script) {
      setScript(paypalScript);
      const script = document?.createElement('script');

      script.src = paypalScript?.slice(0, -3);
      script.async = true;
      script.type = 'text/javascript';
      script.dataset.label = 'paypal';
      script.dataset.color = 'gold';
      script.dataset.shape = 'rect';
      script.dataset.layout = 'vertical';
      script.dataset.height = height || '40';
      script.dataset.borderRadius = '8';

      document.body.appendChild(script);
      dispatch(sendAnalyticEvent({ event: 'paypal_script_appended' }));
    }
    if (!paypalScript && script) setScript(null);
  }, [dispatch, signature, paypalScript, script, height]);
};

export default usePaypalScript;
