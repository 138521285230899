import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useNewEditorFlow = () => {
  const abTests = getJsonFomStorage('abTests');
  const testingNewEditorFlowAB = useFeature(GrowthBookTestType.NEW_EDITOR_FLOW);

  if (abTests?.includes(LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B))
    return LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A as const;
  else if (abTests?.includes(LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A))
    return LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A as const;

  if (testingNewEditorFlowAB?.experiment?.key && testingNewEditorFlowAB?.experimentResult?.value) {
    return `${testingNewEditorFlowAB?.experiment?.key}_${testingNewEditorFlowAB?.experimentResult?.value}` as
      | LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A
      | LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B;
  }

  return LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A as const;
};
