import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 44px 20px 44px 20px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 25px;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0 15px;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Colors-black-grey-white-main, #1d1d1d);
  font-size: 23px;
  font-weight: 900;
  line-height: 32.43px;
  text-align: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 0;
`;

export const DescriptionContainer = styled.div`
  color: var(--Dark-Dark, #111928);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  display: flex;
  align-items: center;
`;

export const FormatsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;

  @media (max-width: 639px) {
    flex-wrap: wrap;
  }
`;

export const FormatItem = styled.div<{ current?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 33.33%;
  padding: 10px 20px;
  border-radius: var(--Radius-xs, 5px);
  border-radius: var(--Radius-xs, 5px);
  border: ${(props) => (props?.current ? '1px solid #3758F9' : '1px solid #EBEEFF')};
  background: ${(props) => (props?.current ? '#EBEEFF' : '#FFF')};
  cursor: pointer;

  @media (max-width: 639px) {
    flex: 100%;
    .format-icon {
      width: 28px;
      height: 28px;
    }
  }
`;

export const FormatLabel = styled.div`
  color: var(--Text-color-tittle, #212e45);
  display: flex;
  align-items: start;
  gap: 8px;

  font-size: 16px;
  font-weight: 900;
  line-height: 20.8px;
  text-align: left;

  @media (max-width: 639px) {
    font-size: 16px;
    width: 100%;
  }
`;

export const FormatImage = styled.img``;

export const FormatDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  margin-left: 30px;
  color: var(--Text-color-tittle, #212e45);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;

  button {
    width: 100%;
  }
`;
