import { FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import useDisableScroll from 'hooks/useDisableScroll';
import useKeyPress from 'hooks/useKeyPress';
import { toggleModal } from 'data/actions/modals';
import { ModalContainer } from './styles';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

const ModalLayout: FC<{
  children: ReactElement;
  customHandleKeyPress?: unknown;
}> = ({ children, customHandleKeyPress }) => {
  const dispatch = useDispatch();

  const handleKeyPress = () => {
    if (typeof customHandleKeyPress === 'function') customHandleKeyPress();
    else dispatch(toggleModal({ visible: false }));
  };

  useDisableScroll();
  useKeyPress({ targetKey: 'Escape', onKeyPressDown: handleKeyPress });
  useCloseModalOnBack();

  return <ModalContainer>{children}</ModalContainer>;
};

export default ModalLayout;
