import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { additionalLanguages, DEFAULT_LANGUAGE } from '../../i18n';

interface PathLocaleProviderProps {
  children: ReactNode;
}

const DEFAULT_LANGUAGE_LOCALE_SEGMENT = '';

export const PathLocaleProvider = ({ children }: PathLocaleProviderProps) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const langFromPath = pathSegments[1];
    if (
      [DEFAULT_LANGUAGE_LOCALE_SEGMENT, ...additionalLanguages]?.includes(langFromPath) &&
      i18n.language !== langFromPath
    ) {
      const newPath = langFromPath || DEFAULT_LANGUAGE;
      i18n.changeLanguage(newPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{children}</>;
};
