import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useDispatch, useSelector } from 'react-redux';

import { userEmailSelector, userIdSelector } from 'data/selectors/user';
import { getCookie } from './useSignUpQuery';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { sendAnalyticEvent } from 'data/actions/analytics';

const useClarity = () => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const email = useSelector(userEmailSelector) || '';

  useEffect(() => {
    if (window.location.hostname === 'localhost') return;
    clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID || 'nbsmmefy59');
  }, []);

  useEffect(() => {
    if (userId && clarity.hasStarted()) {
      clarity.identify(userId, { email });
      clarity.setTag('email', email);

      const abTests = localStorage.getItem('abTests') ? getJsonFomStorage('abTests') : null;
      const clarityCookies = getCookie('_clck');

      if (clarityCookies) {
        const clarityId = clarityCookies.split('|')[0];

        dispatch(sendAnalyticEvent({
          event: 'clarity_init',
          data: {
            clarityId,
          },
        }));
      }

      if (abTests?.length) {
        // set user AB tests tag for clarity
        abTests.forEach((abTest: string) => {
          const parts = abTest.split('_');
          const testValue: string = parts.pop() || 'A'; // 'A'
          const testKey = parts.join('_'); // 'send_file_modal_2_0_1'
          clarity.setTag(testKey, testValue);
        });
      }
    }
  }, [userId, email, dispatch]);
};

export default useClarity;
