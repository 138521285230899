import { Input } from 'antd';
import styled from 'styled-components';

export const InputLabel = styled.span`
  color: var(--Text-secondary, #4c5e7f);
  /* Body/Reg */

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: var(--Red-Red, #f23030);
  /* Caption/Reg */

  font-size: 13.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 21.44px */
`;

export const InputComponent = styled(Input)<{ $errorMessage?: string }>`
  display: flex;
  height: auto;
  align-items: center;
  padding: 12px 16px 12px 20px !important;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px !important;
  background: var(--Primary-white, #fff) !important;
  width: 100%;
  color: var(--Grey-80, #354562) !important;
  margin-top: 10px !important;

  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal;
  line-height: 24px !important;
  border: ${(props) =>
    props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#DFE4EA !important'};

  &::placeholder {
    color: #9ca3af !important;
    /* Body/Reg */

    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 130%; /* 20.8px */
  }

  &:active,
  &:focus,
  &:hover {
    border: ${(props) =>
      props?.$errorMessage ? '1px solid#F23030 !important' : '1px solid#DFE4EA !important'};
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-input-group-addon {
    border: none !important;
    background: var(--Primary-white, #fff) !important;
    padding: 0 !important;
    line-height: 0;
  }

  input {
    border: none !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0 10px !important;
    outline: none !important;

    font-weight: 600 !important;

    &:active,
    &:focus,
    &:hover {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;
