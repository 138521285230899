import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import ErrorStep from './steps/error';
import SuccessStep from './steps/success';
import ModalLayout from 'components/modals/baseModal';
import { modalOptionsSelector } from 'data/selectors/modals';

const GetFreeAccessModal: FC = () => {
  const options = useSelector(modalOptionsSelector);

  const renderStep = (status: string): ReactElement => {
    if (status === 'error') return <ErrorStep />;
    return <SuccessStep />;
  };

  return <ModalLayout>{renderStep(options?.status || 'success')}</ModalLayout>;
};

export default GetFreeAccessModal;
