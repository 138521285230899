import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import openNotification from 'components/commonNotification';
import {
  downloadDocument,
  getParsedTextFromFile,
  getUploadLink,
  parseTextFromDocument,
  setEditDocumentData,
  updateDownloadProgress,
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import {
  isUserAuthenticated,
  userEmailSelector,
  userSubscriptionSelector,
} from 'data/selectors/user';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_RESULT_COUNT_RETRIES, MOBILE_SCREEN_SIZE_WIDTH } from 'ts/constants/general';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { FileType } from 'ts/enums/internal.file';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';
import {
  IOCRDocumentDto,
  OcrExportFormatEnum,
  SupportedOcrExportFormat,
} from 'ts/interfaces/documents/document';
import { EServiceType, IService } from 'ts/interfaces/services/service';
import { formatFileSize } from 'utils/formatFileSize';
import { useModalVsUnitedPayment } from './growthBook/useModalVsUnitedPayment';
import { sendAnalyticEvent } from 'data/actions/analytics';

const EXPORT_FORMAT_OCR_MAP: Record<SupportedOcrExportFormat, FileType> = {
  [OcrExportFormatEnum.docx]: FileType.DOCX,
  [OcrExportFormatEnum.pdfSearchable]: FileType.PDF,
  [OcrExportFormatEnum.txt]: FileType.TXT,
};

const useFileUploadAndOCR = ({ service }: { service?: IService }) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);
  const modalVsUnitedPaymentABC = useModalVsUnitedPayment();

  const goToCheckoutCallback = useMemo(
    () => ({
      [LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A]: () => navigate('/choose-plan'),
      [LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B]: () =>
        dispatch(toggleModal({ type: EModalTypes.MODAL_SELECT_PLAN, visible: true })),
      [LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_C]: () => {
        let newRoute = '/checkout';

        if (window.location.search) {
          const parsed = queryString.parse(window.location.search);

          if (parsed?.['documentId']) newRoute = `${newRoute}?documentId=${parsed?.['documentId']}`;
        }

        navigate(newRoute);
        window.scrollTo(0, 0);
      },
    }),
    [dispatch, navigate]
  );

  const modalVsUnitedPaymentABCVariant = useMemo(() => {
    if (
      window.innerWidth <= MOBILE_SCREEN_SIZE_WIDTH &&
      modalVsUnitedPaymentABC === LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B
    ) {
      return LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A;
    }

    return modalVsUnitedPaymentABC;
  }, [modalVsUnitedPaymentABC]);

  // after upload a file run the progress animation
  const handleUpdateDownloadProgress = () => {
    let progressValue = 90;
    const intervalId = setInterval(() => {
      progressValue += 1;
      dispatch(updateDownloadProgress(progressValue));
      if (progressValue >= 100) clearInterval(intervalId);
    }, 50);
  };

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
    from,
  }: {
    success: boolean;
    size: number;
    from: string;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    dispatch(sendAnalyticEvent({
      event: 'file_upload_status',
      data: {
        status: success ? 'success' : 'fail',
        place: 'additional',
        errorCode,
        size: formatFileSize(size),
        accurate_size: size / 1000000,
        fileCounter: fileCounter || 1,
        file_format: `.${from?.toLowerCase() || '.pdf'}`,
        is_validation_error: success ? 'false' : 'true',
      },
    }));
  };

  const sendDownloadAgainMessage = (documentId: string) => {
    openNotification({
      message: (
        <div>
          {t('notifications.download_started')}{' '}
          <span className="link" onClick={() => dispatch(downloadDocument(documentId))}>
            {t('notifications.download_again')}
          </span>
        </div>
      ),
      type: ENotification.INFO,
      duration: 5,
    });
  };

  const handleParseTextFromDocument = (
    dataToConvert: IOCRDocumentDto,
    signUp?: boolean,
    subscription?: any,
    fileFormat?: string
  ) => {
    if (!userSubscription && !subscription?.id) {
      handleUpdateDownloadProgress();
      return setTimeout(
        () => {
          // TODO: file format
          dispatch(parseTextFromDocument(dataToConvert, fileFormat || ''));

          dispatch(toggleModal({ visible: false }));
          goToCheckoutCallback[modalVsUnitedPaymentABCVariant]();
          window.scrollTo(0, 0); // Scrolls to the top of the page
        },
        signUp ? 0 : 1000
      );
    }

    const onSuccess = (res: any) => {
      let countRetries = 0;

      const interval = setInterval(() => {
        const onSuccessCallback = (res: any) => {
          handleUpdateDownloadProgress();
          clearInterval(interval);

          // awaiting the downloading animation
          setTimeout(() => {
            if (res?.processing_status === 'FAILED')
              return dispatch(
                toggleModal({
                  type: EModalTypes.FILE_UPLOAD_ERROR,
                  visible: true,
                })
              );

            dispatch(toggleModal({ visible: false }));
            sendDownloadAgainMessage(res?.id);

            dispatch(downloadDocument(res?.id));
          }, 1000);
        };
        countRetries += 1;
        if (countRetries < GET_RESULT_COUNT_RETRIES) dispatch(getParsedTextFromFile(res?.fileId, onSuccessCallback));
        else {
          clearInterval(interval);
          dispatch(toggleModal({ visible: true, type: EModalTypes.FILE_UPLOAD_ERROR }));
        }
      }, 1500);
    };

    const onFailed = (error: any) => {
      if (error?.response?.data?.message === 'error.convert.unsupported-formats') {
        dispatch(toggleModal({ visible: false }));
        dispatch(updateDownloadProgress(0));
        return openNotification({
          message: t('global.google_login_error'),
          type: ENotification.ERROR,
          duration: 5,
        });
      }

      goToCheckoutCallback[modalVsUnitedPaymentABCVariant]();
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    dispatch(parseTextFromDocument(dataToConvert, fileFormat ?? '', onSuccess, onFailed));
  };

  const handleConvertFile = (dataToConvert: IOCRDocumentDto) => {
    if (!isAuth) {
      handleUpdateDownloadProgress();
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            visible: true,
            options: {
              handleConvertDocument: (subscription: any) =>
                handleParseTextFromDocument(dataToConvert, true, subscription),
              signUp: true,
              servicePath: service?.path,
            },
          })
        );
      }, 2000);
    }
    handleParseTextFromDocument(dataToConvert);
  };

  const handleUploadFileByLinkToS3 = async (
    file: File,
    uploadLink: string,
    to?: SupportedOcrExportFormat,
    filename?: string
  ) => {
    try {
      dispatch(updateDownloadProgress(0));
      dispatch(
        toggleModal({
          type: EModalTypes.PROGRESS_FILE_OCR,
          visible: true,
          options: { file },
        })
      );

      const res = await axios.put(uploadLink, file, { headers: {} });
      const from = file?.name?.split('.')?.pop()?.toUpperCase() || service?.from || 'PDF';

      const dataToConvert: IOCRDocumentDto = {
        filename: filename ?? file.name,
        size: file.size,
        key: getFileKeyFromAWSLink(res?.request?.responseURL),
        url: res?.request?.responseURL,
        exportFormat: to || 'docx',
        serviceType: EServiceType.OCR,
        to: EXPORT_FORMAT_OCR_MAP[to || 'docx'],
      };

      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(setEditDocumentData(dataToConvert));

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToConvert,
            service: service?.path,
            email: email,
            serviceType: EServiceType.OCR,
            key: getFileKeyFromAWSLink(res?.request?.responseURL),
            filename: filename ?? file.name,
            to: EXPORT_FORMAT_OCR_MAP[to || 'docx'],
          })
        );

        handleConvertFile(dataToConvert);
        analyticsEventUpload({ size: file?.size, success: true, from });
      }, 3000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFile = (file: File, to?: SupportedOcrExportFormat, filename?: string) => {
    if (!file) return;

    dispatch(sendAnalyticEvent({
      event: 'features_tap',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click',
      },
    }));

    dispatch(
      getUploadLink({
        filename: filename ?? file?.name,
        onSuccess: (res: any) => {
          handleUploadFileByLinkToS3(file, res[0]?.url, to, filename);
        },
        service,
      })
    );
  };

  return {
    handleUploadFile,
    handleParseTextFromDocument,
  };
};

export default useFileUploadAndOCR;
