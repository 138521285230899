import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import closeImagePath from 'assets/close-icon.svg';
import { toggleModal } from 'data/actions/modals';
import { recoverPasswordPassword } from 'data/actions/user';
import { ENotification } from 'ts/interfaces/common/notification';
import openNotification from 'components/commonNotification';
import { modalOptionsSelector } from 'data/selectors/modals';
import ModalLayout from 'components/modals/baseModal';
import useKeyPress from 'hooks/useKeyPress';
import CommonInput from 'components/common/input';
import { CloseIcon } from '../baseModal/styles';
import { Container, Title, InputContainer } from './styles';
import CommonButton from 'components/common/button';
import { sendAnalyticEvent } from 'data/actions/analytics';

const RecoverPasswordModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useSelector(modalOptionsSelector);

  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'recover_new_pass_page_view' }));
  }, [dispatch]);

  const handleClose = () => {
    dispatch(toggleModal({ visible: false }));
  };

  const validatePassword = (password: string) => {
    // Check if password length is at least 6 characters
    if (password.length < 8) return t('global.password_must_contain');

    if (password !== repeatPassword) return t('global.password_not_match');
    return null;
  };

  const onFailed = () => {
    openNotification({
      message: t('global.link_expired'),
      type: ENotification.ERROR,
    });
  };

  const handleOnSuccess = () => {
    dispatch(toggleModal({ visible: false }));
    openNotification({
      message: t('global.password_changed_successfully'),
      type: ENotification.SUCCESS,
    });
  };

  const handleChangePassword = () => {
    const errorMessage = validatePassword(password);
    if (errorMessage) {
      return openNotification({
        message: errorMessage,
        type: ENotification.ERROR,
      });
    }

    dispatch(sendAnalyticEvent({ event: 'recover_new_pass_page_tap' }));
    dispatch(
      recoverPasswordPassword({ password, repeatPassword, token }, handleOnSuccess, onFailed)
    );
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleChangePassword });

  return (
    <ModalLayout>
      <Container>
        <CloseIcon onClick={handleClose} src={closeImagePath} alt="" />
        <Title>{t('global.enter_new_password')}</Title>
        <InputContainer>
          <CommonInput
            suggestion={t('global.password_must_contain')}
            label={t('settings_page.new_password')}
            type="password"
            onChange={(value: string) => setPassword(value)}
            value={password}
          />
        </InputContainer>
        <InputContainer>
          <CommonInput
            label={t('settings_page.re_type_new_password')}
            type="password"
            onChange={(value: string) => setRepeatPassword(value)}
            value={repeatPassword}
          />
        </InputContainer>
        <CommonButton type="primary" onClick={handleChangePassword}>
          {t('settings_page.change_password')}
        </CommonButton>
      </Container>
    </ModalLayout>
  );
};

export default RecoverPasswordModal;
