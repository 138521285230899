import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import radioButtonCheckPath from 'assets/radiobutton-check.svg';
import radioButtonPath from 'assets/radiobutton.svg';
import CommonButton from 'components/common/button';
import CommonInput from 'components/common/input';
import ModalLayout from 'components/modals/baseModal';
import {
  downloadDocument,
  updateEditDocumentData,
  updateEditFilename,
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { convertDocumentDataSelector, documentByIdSelector, editFilenameSelector } from 'data/selectors/documents';
import { modalOptionsSelector } from 'data/selectors/modals';
import { isUserAuthenticated, userSubscriptionSelector } from 'data/selectors/user';
import useFileUploadAndConvert from 'hooks/useFileUploadAndConvert';
import { EModalTypes } from 'ts/enums/modal.types';

import { getLoadingSelector } from 'data/selectors/loading';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { useModalVsUnitedPayment } from 'hooks/growthBook/useModalVsUnitedPayment';
import { useNewEditorFlow } from 'hooks/growthBook/useNewEditorFlow';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import queryString from 'query-string';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSearchParams } from 'react-router-dom';
import { MOBILE_SCREEN_SIZE_WIDTH } from 'ts/constants/general';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { CloseIcon } from '../baseModal/styles';
import { formatsList } from './formatsList';
import {
  ButtonsContainer,
  Container,
  Description,
  FormatItem,
  FormatLabel,
  FormatsContainer,
  InputContainer,
  Title,
} from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const ChooseFormatModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const isLoading = useSelector(getLoadingSelector('save_edit_document'));

  const { documentId, service, handleSaveFile } = useSelector(modalOptionsSelector);
  const fileId = useSelector(documentByIdSelector());
  const editFilename = useSelector(editFilenameSelector());

  const dataToConvert = useSelector(convertDocumentDataSelector());
  const { handleConvertDocument } = useFileUploadAndConvert({ service });
  const [searchParams] = useSearchParams();
  const convertTo = searchParams.get('to');
  const newEditorFlowAB = useNewEditorFlow();
  const newEditorFlowVariant = useMemo(() => {
    const parsed = queryString.parse(window.location.search);
    const isAVariantFunnel = parsed?.['documentId'] || !convertTo;
    return isAVariantFunnel ? LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A : newEditorFlowAB;
  }, [convertTo, newEditorFlowAB]);

  const [currentFormat, setCurrentFormat] = useState<{
    format: string;
    label: string;
    to: string;
    icon?: any;
  }>(formatsList[0]);

  const modalVsUnitedPaymentABC = useModalVsUnitedPayment();
  const goToCheckoutCallback = useMemo(
    () => ({
      [LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A]: () => navigate('/choose-plan'),
      [LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B]: () =>
        dispatch(toggleModal({ type: EModalTypes.MODAL_SELECT_PLAN, visible: true })),
      [LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_C]: () => {
        let newRoute = '/checkout';

        if (window.location.search) {
          const parsed = queryString.parse(window.location.search);

          if (parsed?.['documentId']) newRoute = `${newRoute}?documentId=${parsed?.['documentId']}`;
        }

        navigate(newRoute);
        window.scrollTo(0, 0);
      },
    }),
    [dispatch, navigate]
  );

  const modalVsUnitedPaymentABCVariant = useMemo(() => {
    if (
      window.innerWidth <= MOBILE_SCREEN_SIZE_WIDTH &&
      modalVsUnitedPaymentABC === LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B
    ) {
      return LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A;
    }

    return modalVsUnitedPaymentABC;
  }, [modalVsUnitedPaymentABC]);

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'save_edited_file_modal_view' }));
  }, [dispatch]);

  useEffect(() => {
    if (newEditorFlowVariant !== LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B) {
      handleSaveFile(editFilename);
    }
  }, []); // eslint-disable-line

  const handleCancel = () => {
    dispatch(sendAnalyticEvent({ event: 'cancel_save_edited_file' }));
    dispatch(toggleModal({ visible: false }));
  };

  const handleSetCurrentFormat = (format: {
    format: string;
    label: string;
    to: string;
    icon?: any;
  }) => {
    const dataToEdit: any = getJsonFomStorage('dataToEdit');
    if (dataToEdit) {
      dataToEdit.from = 'PDF';
      dataToEdit.to = format.to;
      dataToEdit.documentId = documentId;
    }
    dispatch(updateEditDocumentData({ to: format.to === 'PDF' ? '' : format.to }));
    localStorage.setItem('dataToEdit', JSON.stringify(dataToEdit));

    setCurrentFormat(format);
  };

  useEffect(() => {
    const selectedFormat = formatsList.find((format) => format.to === convertTo);
    if (convertTo && selectedFormat) {
      handleSetCurrentFormat(selectedFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertTo]);

  const runConvertDocument = (signUp: boolean) => {
    dispatch(toggleModal({ visible: true, type: EModalTypes.CONVERTING_FILE }));
    handleConvertDocument(
      {
        filename: `${editFilename}.pdf`,
        key: dataToConvert?.key,
        size: dataToConvert?.size,
        from: 'PDF',
        to: currentFormat.to,
        pagesCount: 1,
        url: dataToConvert?.url,
      },
      signUp
    );
  };

  const handleConvertOrDownloadDocument = (signUp: boolean) => {
    if (!userSubscription) {
      if (currentFormat.to !== 'PDF') return runConvertDocument(signUp);
      return goToCheckoutCallback[modalVsUnitedPaymentABCVariant]();
    }

    if (currentFormat.to !== 'PDF') return runConvertDocument(signUp);
    dispatch(downloadDocument(documentId || fileId.id));
  };

  const handleDownload = () => {
    dispatch(sendAnalyticEvent({
      event: 'select_type_tap',
      data: {
        features_name: getConverterName(currentFormat.to),
        name_changed: dataToConvert?.filename !== editFilename,
      },
    }));
    handleSaveFile(editFilename, currentFormat.to);

    if (newEditorFlowVariant === LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B) {
      return;
    }

    if (!isAuth) {
      return dispatch(
        toggleModal({
          type: EModalTypes.ENTER_EMAIL_ADDRESS,
          visible: true,
          options: {
            handleConvertDocument: (subscription: any) => handleConvertOrDownloadDocument(true),
            signUp: true,
            servicePath: service?.path,
            keepOpenedOnSuccess:
              modalVsUnitedPaymentABCVariant === LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B,
          },
        })
      );
    }

    dispatch(toggleModal({ visible: false }));
    handleConvertOrDownloadDocument(false);
  };

  const getConverterName = (selectedFormat: string) => {
    if (selectedFormat === 'PDF') {
      return `as_pdf`;
    }

    return `pdf_to_${selectedFormat.toLowerCase()}`;
  };

  return (
    <ModalLayout>
      <Container>
        <CloseIcon onClick={handleCancel} src={closeImagePath} alt="" />
        <Title>{t('choose_format_modal.title')}</Title>
        <Description>{t('choose_format_modal.description')}</Description>
        <FormatsContainer>
          {formatsList.map((item, index) => (
            <FormatItem
              key={`modal-format-item-${index + 1}`}
              current={currentFormat.format === item.format}
              onClick={() => handleSetCurrentFormat(item)}
            >
              <LazyLoadImage
                src={currentFormat.format === item.format ? radioButtonCheckPath : radioButtonPath}
                alt="radiobutton"
              />
              <FormatLabel>{item.label}</FormatLabel>
              <LazyLoadImage className="format-icon" src={item.icon} alt="format icon" />
            </FormatItem>
          ))}
        </FormatsContainer>
        <InputContainer>
          <CommonInput
            label={t('global.file_name')}
            placeholder={t('global.name')}
            value={editFilename}
            onChange={(value: string) => {
              dispatch(updateEditFilename(value));
            }}
          />
        </InputContainer>
        <ButtonsContainer>
          <CommonButton style={{ width: '40%' }} onClick={handleCancel} type="tertiary">
            {t('global.cancel')}
          </CommonButton>
          <CommonButton
            disabled={!editFilename || isLoading}
            onClick={handleDownload}
            type="primary"
            style={{ width: '60%' }}
          >
            {isLoading ? (
              <>
                <span>{t('global.downloading')}</span>
                <Spin
                  style={{ marginBottom: '4px' }}
                  indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                />
              </>
            ) : (
              t('global.download')
            )}
          </CommonButton>
        </ButtonsContainer>
      </Container>
    </ModalLayout>
  );
};

export default ChooseFormatModal;
