import { CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px;
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  width: 525px;
  min-height: 443px;
  gap: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const Title = styled(CommonTextH5)`
  color: var(--Grey-80, #354562);
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;
