import { OcrExportFormatEnum, SupportedOcrExportFormat } from 'ts/interfaces/documents/document';

export interface OCRExportFormat {
  label: string;
  description: string;
  format: SupportedOcrExportFormat;
}

export const formatsList: OCRExportFormat[] = [
  {
    label: 'DOCX',
    description: 'service_page.pdf_ocr.formats.docx',
    format: OcrExportFormatEnum.docx,
  },
  {
    label: 'PDF',
    description: 'service_page.pdf_ocr.formats.pdfSearchable',
    format: OcrExportFormatEnum.pdfSearchable,
  },
  {
    label: 'TXT',
    description: 'service_page.pdf_ocr.formats.txt',
    format: OcrExportFormatEnum.txt,
  },
];
