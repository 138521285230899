import { ReactNode } from 'react';

export enum ENotification {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

export interface INotification {
  message: string | ReactNode;
  messageKey?: string;
  description?: string;
  duration?: number;
  type?: ENotification;
  onClick?: () => {};
}
