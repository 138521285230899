import { useRenderFileImage } from 'components/checkout/hooks/useRenderFileImage';
import { getDocumentById, getDocuments } from 'data/actions/documents';
import {
  convertDocumentDataSelector,
  documentByIdSelector,
  editDocumentDataSelector,
} from 'data/selectors/documents';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiFile } from 'ts/types/file';
import { useLoadDocumentCover } from 'utils/loadDocumentCover';
import {
  FileFormat,
  FileName,
  FilePreview,
  ImageFileContainer,
  ImgAndNameContainer,
} from './styles';
import { useLocation } from 'react-router-dom';

export interface FilePreviewProps {
  onClick?: () => void;
}

const SelectPlanFilePreview = ({ onClick }: FilePreviewProps) => {
  const [file, setFile] = useState<ApiFile | undefined>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryDocumentID = queryParams.get('documentId');
  
  const documentById: any = useSelector(documentByIdSelector());
  const dataToConvert = useMemo(() => {
    return getJsonFomStorage('dataToConvert');
  }, []);

  const convertDocumentData = useSelector(convertDocumentDataSelector());
  const editDocumentData = useSelector(editDocumentDataSelector());
  const dispatch = useDispatch();

  const getFileFormatTo = useMemo(() => {
    if (queryDocumentID && documentById?.format) return documentById?.format;
    if (convertDocumentData?.serviceType === 'EDITOR')
      return editDocumentData?.to ? editDocumentData?.to : 'PDF';
    return convertDocumentData?.to || convertDocumentData?.file?.to || '';
  }, [convertDocumentData, editDocumentData, queryDocumentID, documentById]);

  const renderFileName = useMemo(() => {
    if (queryDocumentID && documentById?.filename) return documentById?.filename;
    if (convertDocumentData?.serviceType === 'MERGER') return 'merged.pdf';

    let fileName = documentById?.filename || convertDocumentData?.file?.filename || '';
    if (convertDocumentData?.serviceType === 'EDITOR' && editDocumentData?.file?.filename)
      fileName = editDocumentData?.file?.filename;

    if (fileName?.length > 15)
      return fileName.slice(0, 21).replace(/\.[^.]+$/, '') + `...${getFileFormatTo.toLowerCase()}`;
    return fileName.replace(/\.[^.]+$/, `.${getFileFormatTo.toLowerCase()}`);
  }, [getFileFormatTo, documentById, editDocumentData, convertDocumentData, queryDocumentID]);

  const { isImageLoading, imagePDF, imageCover } = useLoadDocumentCover({
    file,
    fileUrl: documentById?.url || dataToConvert?.file?.url,
  });

  const fileRenderImage = useRenderFileImage({
    imagePDF,
    imageCover,
    isImageLoading,
    formatFrom: documentById?.format || dataToConvert?.file?.from,
    formatTo: getFileFormatTo,
    FilePreview,
  });

  useEffect(() => {
    return () => {
      setFile(undefined);
    };
  }, []);

  useEffect(() => {
    if (queryDocumentID) {
      dispatch(
        getDocuments((res: any) => {
          dispatch(getDocumentById(queryDocumentID, () => {
            setFile(res?.files?.find((file: ApiFile) => file.id === queryDocumentID));
          }));
        })
      );
      return;
    }

    setTimeout(() => {
      dispatch(
        getDocuments((res: any) => {
          const lastFile = res?.files[res?.files?.length - 1];
          dispatch(getDocumentById(lastFile?.id, () => {
            setFile(lastFile);
          }));
        })
      );
    }, 500)
  }, [dispatch, queryDocumentID]);

  return (
    <ImgAndNameContainer>
      <ImageFileContainer onClick={() => onClick?.()}>
        {fileRenderImage && <FileFormat>{getFileFormatTo}</FileFormat>}
        {fileRenderImage}
      </ImageFileContainer>
      <FileName>{renderFileName}</FileName>
    </ImgAndNameContainer>
  );
};

export default SelectPlanFilePreview;
