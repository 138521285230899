import { IPaginationMeta } from 'ts/interfaces/common/pagination.meta';

export enum PaymentMethod {
  Card = 'Card',
  Paypal = 'paypal-vault',
}

export interface IOrder {
  _id: string;
  name: string;
  createdAt: string;
  price: string;
  status: string;
}

export interface IOrderState {
  data: IOrder[];
  meta?: IPaginationMeta;
}
