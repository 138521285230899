import closeImagePath from 'assets/close-icon.svg';
import PaymentFormTestB from 'components/paymentForm/tests/abc_modal_vs_united_payment_2_0_7/paymentFormTestB';
import { toggleModal } from 'data/actions/modals';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CloseIcon, ModalContainer } from '../baseModal/styles';
import { StyledModalContainer } from './styles';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import { sendAnalyticEvent } from 'data/actions/analytics';

const PaymentModal = () => {
  const dispatch = useDispatch();
  useCloseModalOnBack();

  const handleCloseModal = useCallback(() => {
    dispatch(sendAnalyticEvent({ event: 'payment_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  }, [dispatch]);
  
  return (
    <ModalContainer>
      <StyledModalContainer>
        <CloseIcon onClick={handleCloseModal} src={closeImagePath} alt="" />
        <PaymentFormTestB />
      </StyledModalContainer>
    </ModalContainer>
  );
};

export default PaymentModal;
