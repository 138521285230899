import {
  aiPlansJapaneseSelector,
  aiPlansRecurrentSelector,
  plansJapaneseSelector,
  plansRecurrentSelector,
} from 'data/selectors/subscriptions';
import { isJapanUserSelector } from 'data/selectors/user';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { IPlan, IPlanAI } from 'ts/interfaces/plan/plan';
import { useSalesWithAI } from './growthBook/useSalesWithAI';

export const usePlans = (): IPlan[] | IPlanAI[] => {
  const isJapanUser = useSelector(isJapanUserSelector);

  const defaultPlans = useSelector(plansRecurrentSelector);
  const defaultPlansAI = useSelector(aiPlansRecurrentSelector);
  const japanesePlans = useSelector(plansJapaneseSelector);
  const japanesePlansAI = useSelector(aiPlansJapaneseSelector);

  const salesScreenWithAI = useSalesWithAI();

  const defaultPlansMap = useMemo(
    () => ({
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_A]: defaultPlans,
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_B]: defaultPlansAI,
    }),
    [defaultPlans, defaultPlansAI]
  );

  const japanesePlansMap = useMemo(
    () => ({
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_A]: japanesePlans,
      [LocalStorageProperties.SALES_SCREEN_WITH_AI_B]: japanesePlansAI,
    }),
    [japanesePlans, japanesePlansAI]
  );

  return useMemo(() => {
    if (isJapanUser) return japanesePlansMap[salesScreenWithAI];

    return defaultPlansMap[salesScreenWithAI];
  }, [isJapanUser, japanesePlansMap, salesScreenWithAI, defaultPlansMap]);
};
