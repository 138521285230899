import styled from 'styled-components';

export const ImgAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  border-radius: var(--Radius-s, 10px);
  padding: 30px 50px;
  position: relative;
  gap: 15px;
  border-radius: var(--Radiusxs);
  border: 1px 0px 0px 0px;
  opacity: 0px;
`;

export const ImageFileContainer = styled.div`
  width: max-content;
  width: 227px;
  height: 300px;
  position: relative;
  border-radius: var(--Radius-s, 10px);

  @keyframes fadeInHeight {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  img {
    animation-name: fadeInHeight;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }

  @media (max-width: 870px) {
    height: auto;
    width: 90px;
    height: 120px;
  }
`;

export const FileName = styled.div`
  overflow: hidden;
  color: var(--Text-color-subtitle, #4c5e7f);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
  width: 100%;
  text-align: center;
`;

export const FileFormat = styled.div`
  color: var(--Color, #fff);
  z-index: 100;
  /* Mobile/Body/Extrabold */

  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 19.2px */
  padding: var(--Radius-xs, 5px) var(--Radius-s, 10px);
  position: absolute;
  border-radius: var(--Radius-xs, 5px);
  background: var(--Blue-50, #3758f9);
  left: -30px;
  top: 25px;
`;

export const FilePreview = styled.img<any>`
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-right: 20px;
  filter: blur(3px);
  border: 2px solid var(--Stroke-Color, #dfe4ea);

  @media (max-width: 760px) {
    font-size: 16px;
  }
`;
