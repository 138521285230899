import { useFeature } from '@growthbook/growthbook-react';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { GrowthBookTestType, LocalStorageProperties } from 'ts/enums/growthbook';

export const useSalesWithAI = () => {
  const abTests = getJsonFomStorage('abTests');
  const testingSalesScreenWithAI = useFeature(GrowthBookTestType.SALES_SCREEN_WITH_AI);

  if (abTests?.includes(LocalStorageProperties.SALES_SCREEN_WITH_AI_B))
    return LocalStorageProperties.SALES_SCREEN_WITH_AI_B as const;
  else if (abTests?.includes(LocalStorageProperties.SALES_SCREEN_WITH_AI_A))
    return LocalStorageProperties.SALES_SCREEN_WITH_AI_A as const;

  if (
    testingSalesScreenWithAI?.experiment?.key &&
    testingSalesScreenWithAI?.experimentResult?.value
  ) {
    return `${testingSalesScreenWithAI?.experiment?.key}_${testingSalesScreenWithAI?.experimentResult?.value}` as
      | LocalStorageProperties.SALES_SCREEN_WITH_AI_A
      | LocalStorageProperties.SALES_SCREEN_WITH_AI_B;
  }

  return LocalStorageProperties.SALES_SCREEN_WITH_AI_A as const;
};
