import { appStorage } from 'utils/appStorage';

export const MARKETING_VALUES_KEY = 'marketing';
export const storeMarketingValues = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const storedValues: any = appStorage.getItem(MARKETING_VALUES_KEY);
  let data: any = storedValues ? JSON.parse(storedValues) : {};

  if (params['id_partner']) {
    data.id_partner = params['id_partner'];
  }
  if (params['feature']) {
    data.feature = params['feature'];
  }
  if (params['clickid']) {
    data.clickid = params['clickid'];
  }
  if (params['campid']) {
    data.campid = params['campid'];
  }
  if (params['agid']) {
    data.agid = params['agid'];
  }
  if (params['keyword']) {
    data.keyword = params['keyword'];
  }
  if (params['matchtype']) {
    data.matchtype = params['matchtype'];
  }
  if (params['creatid']) {
    data.creatid = params['creatid'];
  }
  if (params['extid']) {
    data.extid = params['extid'];
  }
  if (params['targetid']) {
    data.targetid = params['targetid'];
  }
  if (params['device']) {
    data.device = params['device'];
  }
  if (params['devmod']) {
    data.devmod = params['devmod'];
  }
  if (params['placement']) {
    data.placement = params['placement'];
  }
  if (params['adpos']) {
    data.adpos = params['adpos'];
  }
  if (params['language']) {
    data.language = params['language'];
  }
  if (params['utm_source']) {
    data.source = params['utm_source'];
  }
  if (params['file']) {
    data.file = params['file'];
  }
  if (params['convertedTo']) {
    data.convertedTo = params['convertedTo'];
  }
  if (params['convertedFrom']) {
    data.convertedFrom = params['convertedFrom'];
  }
  if (params['form']) {
    data.form = params['form'];
  }
  if (params['editedFile']) {
    data.editedFile = params['editedFile'];
  }
  if (params['payment']) {
    data.payment = params['payment'];
  }
  if (params['wbraid']) {
    data.wbraid = params['wbraid'];
  }

  data['marketing_url'] = window.location.href;
  data = { ...data, ...params };

  if (Object.keys(data).length > 0) {
    appStorage.setItem(MARKETING_VALUES_KEY, JSON.stringify(data));
  }
};

export const getMarketingValues = (): MarketingValues | null => {
  const storedValues = appStorage.getItem(MARKETING_VALUES_KEY);
  if (!storedValues) return null;

  return JSON.parse(storedValues) as MarketingValues;
};

export type MarketingValues = {
  id_partner?: string;
  feature?: string;
  clickid?: string;
  campid?: string;
  agid?: string;
  keyword?: string;
  matchtype?: string;
  creatid?: string;
  extid?: string;
  targetid?: string;
  device?: string;
  devmod?: string;
  placement?: string;
  adpos?: string;
  //
  language?: string;
  source?: string;
  file?: string;
  convertedTo?: string;
  convertedFrom?: string;
  form?: string;
  editedFile?: string;
  payment?: string;
  wbraid?: string;
};
