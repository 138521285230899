import { createSelector } from 'reselect';

const baseModalsSelector = (state: any) => state?.modals;

export const modalsSelector = createSelector(baseModalsSelector, (modals) => modals);

export const modalOptionsSelector = createSelector(
  baseModalsSelector,
  (modals) => modals?.options || {}
);
