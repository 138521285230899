export enum EModalTypes {
  AUTH_FORM = 'AUTH_FORM',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  PROGRESS_FILE = 'PROGRESS_FILE',
  PROGRESS_FILE_OCR = 'PROGRESS_FILE_OCR',
  ENTER_EMAIL_ADDRESS = 'ENTER_EMAIL_ADDRESS',
  CANCEL_PLAN = 'CANCEL_PLAN',
  CHECK_YOUR_EMAIL = 'CHECK_YOUR_EMAIL',
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR',
  THANK_YOU_PAYMENT = 'THANK_YOU_PAYMENT',
  DARG_AND_DROP = 'DARG_AND_DROP',
  CHOOSE_FORMAT = 'CHOOSE_FORMAT',
  CHOOSE_FORMAT_AND_CONVERT = 'CHOOSE_FORMAT_AND_CONVERT',
  CHOOSE_FORMAT_AND_PARSE_TEXT = 'CHOOSE_FORMAT_AND_PARSE_TEXT',
  PROGRESS_EDIT_FILE = 'PROGRESS_EDIT_FILE',
  CONVERTING_FILE = 'CONVERTING_FILE',
  PROCESSING_COMPRESS = 'PROCESSING_COMPRESS',
  PROCESSING_SPLIT = 'PROCESSING_SPLIT',
  PROCESSING_MERGE = 'PROCESSING_MERGE',
  TRUST_PILOT = 'TRUST_PILOT',
  THANK_SUBSCRIPTION_OFFER_MODAL = 'THANK_SUBSCRIPTION_OFFER_MODAL',
  GET_FREE_ACCESS = 'GET_FREE_ACCESS',
  QUICK_ACTIONS = 'QUICK_ACTIONS',
  MODAL_SELECT_PLAN = 'MODAL_SELECT_PLAN',
  PAYMENT_MODAL = 'PAYMENT_MODAL',
}
