import { Collapse } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { toggleModal } from 'data/actions/modals';
import { quickActionsWithGroups } from 'helpers/quickActions';
import { MobileActionItem, MobileActionLabel, MobileActions, MobileContainer } from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const generateTabs = (t: Function, handleClickAction: Function) => [
  {
    key: '1',
    label: (
      <MobileActionLabel>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="24"
          viewBox="0 0 23 24"
          fill="none"
        >
          <path
            d="M8.02344 22.0791H3.02344C2.63281 22.0791 2.32031 21.7666 2.32031 21.376V2.62598C2.32031 2.23536 2.63281 1.92286 3.02344 1.92286H11.8516V6.92286C11.8516 7.39161 12.2422 7.82129 12.75 7.82129H17.7109V10.4385C17.7109 10.9072 18.1016 11.3369 18.6094 11.3369C19.1172 11.3369 19.5078 10.9463 19.5078 10.4385V7.15723C19.5078 6.72754 19.3125 6.29786 19 5.98536L13.5313 0.672856C13.2188 0.360356 12.7891 0.204106 12.3594 0.204106H2.98437C1.65625 0.165044 0.5625 1.29786 0.5625 2.62598V21.376C0.5625 22.7041 1.65625 23.8369 3.02344 23.8369H8.0625C8.53125 23.8369 8.96094 23.4463 8.96094 22.9385C8.96094 22.4307 8.53125 22.0791 8.02344 22.0791ZM13.5703 3.17286L16.5 6.06348H13.5703V3.17286Z"
            fill="#4C5E7F"
          />
          <path
            d="M22.125 14.1895C21.8125 13.877 21.5 13.5645 21.1875 13.252C20.9141 12.9785 20.6406 12.666 20.3281 12.3926C20.1719 12.1973 19.9375 12.041 19.6641 12.002C19.3516 11.9629 19.0391 12.041 18.8047 12.2363L12.0078 18.9941C11.8125 19.1895 11.6953 19.3848 11.6172 19.6191L10.6406 22.5879L10.4844 23.0566L10.7578 23.4082C10.875 23.5645 11.1094 23.7988 11.5391 23.7988H11.6953L14.7813 22.7832C15.0156 22.7051 15.25 22.5879 15.4063 22.3926L22.125 15.7129C22.3203 15.5176 22.4375 15.2441 22.4375 14.9316C22.4375 14.6582 22.3203 14.3848 22.125 14.1895ZM19.5078 14.0332C19.6641 14.1895 19.8203 14.3457 19.9375 14.502C20.0937 14.6582 20.25 14.8145 20.4062 14.9707L19.9766 15.4004L19.0781 14.502L19.5078 14.0332ZM14.1953 21.1426L12.8281 21.5723L13.2578 20.2051L17.7891 15.6738L18.6875 16.5723L14.1953 21.1426Z"
            fill="#4C5E7F"
          />
        </svg>
        {t('global.pdf_editor')}
      </MobileActionLabel>
    ),
    children: (
      <MobileActions>
        {quickActionsWithGroups.editPdf.map((group, index) => (
          <MobileActionItem
            to={group.path}
            key={`quick-actions-edit-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            {t(group.name)}
          </MobileActionItem>
        ))}
      </MobileActions>
    ),
  },
  {
    key: '2',
    label: (
      <MobileActionLabel>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.9998 23.3219H2.9998C1.7248 23.3219 0.674805 22.2719 0.674805 20.9969V2.99688C0.674805 1.72187 1.7248 0.671875 2.9998 0.671875H20.9998C22.2748 0.671875 23.3248 1.72187 23.3248 2.99688V20.9969C23.3248 22.2719 22.2748 23.3219 20.9998 23.3219ZM2.9998 2.32187C2.6248 2.32187 2.3248 2.62188 2.3248 2.99688V20.9969C2.3248 21.3719 2.6248 21.6719 2.9998 21.6719H20.9998C21.3748 21.6719 21.6748 21.3719 21.6748 20.9969V2.99688C21.6748 2.62188 21.3748 2.32187 20.9998 2.32187H2.9998Z"
            fill="#4C5E7F"
          />
          <path
            d="M12.6373 16.9453C12.4123 16.9453 12.1873 16.8703 12.0373 16.6828C11.6998 16.3453 11.6998 15.8203 12.0373 15.4828L14.6248 12.8953H7.3123C6.8623 12.8953 6.4873 12.5203 6.4873 12.0703C6.4873 11.6203 6.8623 11.2453 7.3123 11.2453H14.6248L11.8873 8.50781C11.5498 8.17031 11.5498 7.64531 11.8873 7.30781C12.2248 6.97031 12.7498 6.97031 13.0873 7.30781L17.2498 11.4703C17.3998 11.6203 17.5123 11.8453 17.5123 12.0703C17.5123 12.2953 17.4373 12.5203 17.2498 12.6703L13.2373 16.6828C13.0873 16.8703 12.8623 16.9453 12.6373 16.9453Z"
            fill="#4C5E7F"
          />
        </svg>
        {t('global.convert_to_pdf')}
      </MobileActionLabel>
    ),
    children: (
      <MobileActions>
        {quickActionsWithGroups.convertToPDF.map((group, index) => (
          <MobileActionItem
            to={group.path}
            key={`quick-actions-convert-to-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            {t(group.name)}
          </MobileActionItem>
        ))}
      </MobileActions>
    ),
  },
  {
    key: '3',
    label: (
      <MobileActionLabel>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3.00019 0.670309L21.0002 0.670311C22.2752 0.670311 23.3252 1.72031 23.3252 2.99531L23.3252 20.9953C23.3252 22.2703 22.2752 23.3203 21.0002 23.3203L3.00019 23.3203C1.72519 23.3203 0.675194 22.2703 0.675194 20.9953L0.675196 2.99531C0.675196 1.72031 1.7252 0.670309 3.00019 0.670309ZM21.0002 21.6703C21.3752 21.6703 21.6752 21.3703 21.6752 20.9953L21.6752 2.99531C21.6752 2.62031 21.3752 2.32031 21.0002 2.32031L3.00019 2.32031C2.62519 2.32031 2.3252 2.62031 2.3252 2.99531L2.32519 20.9953C2.32519 21.3703 2.62519 21.6703 3.00019 21.6703L21.0002 21.6703Z"
            fill="#4C5E7F"
          />
          <path
            d="M11.3627 7.04687C11.5877 7.04687 11.8127 7.12188 11.9627 7.30938C12.3002 7.64687 12.3002 8.17188 11.9627 8.50938L9.3752 11.0969L16.6877 11.0969C17.1377 11.0969 17.5127 11.4719 17.5127 11.9219C17.5127 12.3719 17.1377 12.7469 16.6877 12.7469L9.37519 12.7469L12.1127 15.4844C12.4502 15.8219 12.4502 16.3469 12.1127 16.6844C11.7752 17.0219 11.2502 17.0219 10.9127 16.6844L6.75019 12.5219C6.6002 12.3719 6.4877 12.1469 6.4877 11.9219C6.4877 11.6969 6.5627 11.4719 6.7502 11.3219L10.7627 7.30938C10.9127 7.12188 11.1377 7.04687 11.3627 7.04687Z"
            fill="#4C5E7F"
          />
        </svg>
        {t('global.convert_from_pdf')}
      </MobileActionLabel>
    ),
    children: (
      <MobileActions>
        {quickActionsWithGroups.convertFromPDF.map((group, index) => (
          <MobileActionItem
            to={group.path}
            key={`quick-actions-convert-from-pdf-${index + 1}`}
            onClick={() => handleClickAction(t(group.name))}
          >
            {t(group.name)}
          </MobileActionItem>
        ))}
      </MobileActions>
    ),
  },
];

const QuickActionsMobile: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickAction = (name: string) => {
    dispatch(toggleModal({ visible: false }));
    dispatch(sendAnalyticEvent({ event: 'quick_actions_modal_tap', data: { features_name: name } }));
  };

  return (
    <MobileContainer>
      <Collapse
        style={{ width: '100%' }}
        items={generateTabs(t, handleClickAction)}
        bordered={false}
        expandIconPosition="end"
        ghost
        accordion
      />
    </MobileContainer>
  );
};

export default QuickActionsMobile;
