import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import GoogleIcon from 'assets/google.svg';
import LogoImage from 'assets/logo.svg';
import CommonButton from 'components/common/button';
import EmailAutoCompleteInput from 'components/common/inputEmailAutoComplete';
import CommonInputPassword from 'components/common/inputPassword';
import CommonLink from 'components/common/link';
import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { createAnonymousUser, getUser, signUp } from 'data/actions/user';
import { modalOptionsSelector } from 'data/selectors/modals';
import buildOauthRequestUrl from 'helpers/buildOauthRequestUrl';
import { isEmailValid, isValidPassword } from 'helpers/validation';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import useKeyPress from 'hooks/useKeyPress';
import { EModalTypes } from 'ts/enums/modal.types';
import { ENotification } from 'ts/interfaces/common/notification';

import {
  CloseIcon,
  Container,
  ContentWrapper,
  Description,
  GoogleButton,
  Header,
  InputContainer,
  InputsWrapper,
  LabeledDivider,
  Logo,
  LogoContainer,
  SignUpLink,
  Title,
} from './styles';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AuthFormStep } from './types.d';
import { sendAnalyticEvent } from 'data/actions/analytics';

const SignUp: FC<{ setStep: Dispatch<SetStateAction<AuthFormStep>> }> = ({ setStep }) => {
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleConvertDocument } = useSelector(modalOptionsSelector);
  const passwordInputRef = useRef<any>();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState({ password: '', email: '' });

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'sign_up_page_view' }));
  }, [dispatch]);

  const connectGmailAccount = (e: any) => {
    const url = buildOauthRequestUrl();
    window.location.href = url;
    dispatch(sendAnalyticEvent({ event: 'continue_google_tap' }));
  };

  const validatePassword = (password: string) => {
    const errorMessage = isValidPassword(password);
    setErrors((prev) => ({ ...prev, password: errorMessage }));
    return !errorMessage?.length;
  };

  const validateEmail = useCallback(
    (email: string) => {
      if (!isEmailValid(email)) {
        setErrors((prev) => ({
          ...prev,
          email: t('global.please_enter_valid_email'),
        }));
        return false;
      }
      setErrors((prev) => ({ ...prev, email: '' }));
      return true;
    },
    [t]
  );

  const handleSignUp = useCallback(() => {
    const handleNavigate = (route: string) => {
      dispatch(toggleModal({ visible: false }));
      navigate(route);
      window.scrollTo(0, 0); // Scrolls to the top of the page
    };

    const onSuccessSignUp = () => {
      dispatch(getUser({ onFailed: () => dispatch(createAnonymousUser()) }));

      dispatch(sendAnalyticEvent({
        event: 'email_enter_tap',
        data: { email, type: 'email' },
      }));

      if (typeof handleConvertDocument === 'function') {
        handleConvertDocument();
        return dispatch(toggleModal({ visible: false }));
      }
      handleNavigate('/app/dashboard');
      dispatch(toggleModal({ visible: false }));
    };

    const onFailedSignUp = () => {
      dispatch(sendAnalyticEvent({
        event: 'email_already_exist_popup',
      }));
      openNotification({
        message: (
          <div>
            {t('auth_form.this_email_already_exist')}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => dispatch(toggleModal({ visible: true, type: EModalTypes.AUTH_FORM }))}
            >
              {' '}
              {t('auth_form.log_in')}
            </span>
            .
          </div>
        ),
        type: ENotification.ERROR,
        duration: 10,
      });
    };

    const isValidEmail = validateEmail(email);
    if (passwordInputRef?.current && isValidEmail) {
      passwordInputRef?.current?.focus();
    }

    const isValidPass = validatePassword(password);
    if (!isValidPass || !isValidEmail) return;

    dispatch(signUp(email, password, onSuccessSignUp, onFailedSignUp));
  }, [email, password, dispatch, navigate, handleConvertDocument, t, validateEmail]);

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleSignUp });

  const descriptionElement = useMemo(
    () => (
      <Description>
        {t('auth_form.by_creating_account_means')}{' '}
        <CommonLink size="13.4px" to="/terms" target="_blank">
          {t('global.terms_and_conditions')}
        </CommonLink>
        , {t('auth_form.and_our')}{' '}
        <CommonLink size="13.4px" to="/privacy-policy" target="_blank">
          {t('global.privacy_policy')}
        </CommonLink>
      </Description>
    ),
    [t]
  );

  return (
    <Container>
      <LogoContainer>
        <Logo src={LogoImage} alt="TheBestPDF logo" />
        <CloseIcon
          onClick={() => dispatch(toggleModal({ visible: false }))}
          src={closeImagePath}
          alt=""
        />
      </LogoContainer>

      <ContentWrapper>
        <Header>
          <div>
            <span>{t('auth_form.have_an_account')}</span>
            <SignUpLink onClick={() => setStep(AuthFormStep['sign-in'])}>
              {t('auth_form.log_in')}
            </SignUpLink>
          </div>
        </Header>

        <Title>{t('auth_form.sign_up')}</Title>

        <GoogleButton onClick={connectGmailAccount}>
          <LazyLoadImage src={GoogleIcon} alt="google-icon" />
          {t('auth_form.continue_with_google')}
        </GoogleButton>
        <LabeledDivider>
          <span>{t('global.or')}</span>
        </LabeledDivider>

        <InputsWrapper>
          <InputContainer>
            <EmailAutoCompleteInput
              onChange={(value: string) => setEmail(value)}
              value={email}
              placeholder={t('auth_form.email')}
              label={t('auth_form.email_address')}
              error={errors.email}
            />
          </InputContainer>

          <InputContainer>
            <CommonInputPassword
              onChange={(value: string) => setPassword(value)}
              value={password}
              errorMessage={errors.password}
              placeholder={t('auth_form.password')}
              label={t('auth_form.password')}
              passwordInputRef={passwordInputRef}
              suggestion={t('auth_form.password_must_contain')}
            />
          </InputContainer>
        </InputsWrapper>

        <CommonButton type="primary" onClick={() => handleSignUp()}>
          {t('auth_form.sign_up')}
        </CommonButton>

        {descriptionElement}
      </ContentWrapper>
    </Container>
  );
};

export default SignUp;
