/**
 * @description detect device based on screen resolution
 */
import UAParser from 'ua-parser-js';

const parsedUA = new UAParser().getResult();

export const {
  os: { name: osName, version: osVersion },
} = parsedUA;

export const { browser } = parsedUA;

type ResolutionsMap = Record<string, string[]>;

export const detectDevice = () => {
  const appleDevices: ResolutionsMap = {
    '640x1136': ['iPhone 5/5s/SE', 'iPod touch (6th gen)'],
    '1536x2048': ['iPad Air/2', 'iPad Mini 2/3/4', 'iPad Pro (1st gen 9.7-inch)'],
    '750x1334': ['iPhone 6/6s/7/8'],
    '1242x2208': ['iPhone 6 Plus/6s Plus/7 Plus/8 Plus'],
    '2048x2732': ['iPad Pro (1st gen 12.9-inch)/(2th gen 12.9-inch)/(3rd gen 12.9-inch)'],
    '1668x2224': ['iPad Pro (2th gen 10.5-inch)'],
    '1125x2436': ['iPhone X/Xs/11 Pro'],
    '828x1792': ['iPhone Xr/11'],
    '1242x2688': ['iPhone X Max/Xs Max/11 Pro Max'],
    '1668x2388': ['iPad Pro (3rd gen 11-inch)'],
    '1080x2340': ['iPhone 12 mini'],
    '1170x2532': ['iPhone 12/12 Pro'],
    '1284x2778': ['iPhone 12 Pro Max'],
  };

  const androidDevices: ResolutionsMap = {
    '480x800': ['Samsung Galaxy S'],
    '720x1280': ['Samsung Galaxy J7'],
    '1080x1920': [
      'Samsung Galaxy S5',
      'Google Pixel',
      'Sony Xperia Z3/Z5',
      'Huawei P8',
      'Xiaomi Mi Note/Mi 4i',
    ],
    '1080x2340': ['Sumsung Galaxy A30/50s/50', 'Xiaomi Redmi Note 9 Pro'],
    '1080x2400': ['Sumsung Galaxy A71/70s/51', 'Xiaomi Redmi Note 9/8 Pro/8'],
    '1440x2560': ['Sumsung Galaxy S6'],
    '1440x2960': ['Sumsung Galaxy S8+'],
  };

  function getResolution() {
    if (typeof window === 'undefined') return '';
    const ratio = window.devicePixelRatio || 1;
    return `${Math.trunc(Math.min(window.screen.width, window.screen.height) * ratio)}x${Math.trunc(
      Math.max(window.screen.width, window.screen.height) * ratio
    )}`;
  }
  function getModel() {
    let device;

    if (osName === 'Android') device = androidDevices[getResolution()];
    else device = appleDevices[getResolution()];
    return {
      os: `${osName} ${osVersion}`,
      device: device ? device.join(' or ') : 'unknown',
    };
  }
  return getModel();
};
