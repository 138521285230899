import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import closeImagePath from 'assets/close-icon.svg';
import CommonButton from 'components/common/button';
import ModalLayout from 'components/modals/baseModal';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import { useModalVsUnitedPayment } from 'hooks/growthBook/useModalVsUnitedPayment';
import { useTranslation } from 'react-i18next';
import { MOBILE_SCREEN_SIZE_WIDTH } from 'ts/constants/general';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import { CloseIcon } from '../baseModal/styles';
import { getErrorText, getErrorTitle, getIconPath } from './getContentFunctions';
import { Container, Description, Image, ImageContainer, Title } from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const PaymentErrorModal: FC = () => {
  const dispatch = useDispatch();
  const options = useSelector(modalOptionsSelector);
  const { t } = useTranslation();
  const modalVsUnitedPaymentABC = useModalVsUnitedPayment();
  const modalVsUnitedPaymentABCVariant = useMemo(() => {
    if (
      window.innerWidth <= MOBILE_SCREEN_SIZE_WIDTH &&
      modalVsUnitedPaymentABC === LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B
    ) {
      return LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_A;
    }

    return modalVsUnitedPaymentABC;
  }, [modalVsUnitedPaymentABC]);

  useEffect(() => {
    dispatch(sendAnalyticEvent({
      event: 'payment_declined_modal_view',
      data: {
        error_code: options?.errorCode || '',
        error_message: options?.errorMessage || '',
      },
    }));
  }, []); // eslint-disable-line

  const handleTryAgain = () => {
    dispatch(sendAnalyticEvent({
      event: 'payment_declined_confirm_tap',
    }));
    if (typeof options?.handleTryAgain === 'function') options?.handleTryAgain();
    window.scrollTo(0, 0);
    if (modalVsUnitedPaymentABCVariant === LocalStorageProperties.MODAL_VS_UNITED_PAYMENT_B) {
      dispatch(toggleModal({ type: EModalTypes.PAYMENT_MODAL, visible: true }));
    } else {
      dispatch(toggleModal({ visible: false }));
    }
  };

  const customHandleKeyPress = () => {
    handleTryAgain();
  };

  return (
    <ModalLayout customHandleKeyPress={customHandleKeyPress}>
      <Container>
        <CloseIcon onClick={handleTryAgain} src={closeImagePath} alt="" />
        <ImageContainer>
          <Image alt="" src={getIconPath(options?.errorCode)} />
        </ImageContainer>
        <Title>{getErrorTitle(options?.errorCode, t)}</Title>
        <Description>{getErrorText(options?.errorCode, t)}</Description>
        <CommonButton type="primary" onClick={handleTryAgain}>
          {t('global.try_again')}
        </CommonButton>
      </Container>
    </ModalLayout>
  );
};

export default PaymentErrorModal;
