import { Upload } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import openNotification from 'components/commonNotification';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import useDisableScroll from 'hooks/useDisableScroll';
import useFileUploadAndConvert from 'hooks/useFileUploadAndConvert';
import { useDispatch, useSelector } from 'react-redux';
import { ENotification } from 'ts/interfaces/common/notification';

import { pushMergeDocument } from 'data/actions/documents';
import { mergeDocumentsListSelector } from 'data/selectors/documents';
import { validateFile } from 'helpers/validation';
import useFileUploadAndCompressNewEditorFlow207 from 'hooks/ab_new_editor_flow_2_0_7/useFileUploadAndCompress';
import useFileUploadAndConverteNewEditorFlow207 from 'hooks/ab_new_editor_flow_2_0_7/useFileUploadAndConvert';
import { useNewEditorFlow } from 'hooks/growthBook/useNewEditorFlow';
import { useCustomNavigate } from 'hooks/useCustomNavigate';
import useFileUploadAndCompress from 'hooks/useFileUploadAndCompress';
import useFileUploadAndEdit from 'hooks/useFileUploadAndEdit';
import useFileUploadAndSplit from 'hooks/useFileUploadAndSplit';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import { EServiceType } from 'ts/interfaces/services/service';
import { formatFileSize } from 'utils/formatFileSize';
import { ModalContainer } from '../baseModal/styles';
import { Container, Title } from './styles';
import useFileUploadAndMerge from 'hooks/useFileUploadAndMerge';
import { mergePageService } from 'helpers/servicesList';
import { sendAnalyticEvent } from 'data/actions/analytics';

const DragAndDropModal: FC = () => {
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const mergeDocumentsList: any = useSelector(mergeDocumentsListSelector());
  const options = useSelector(modalOptionsSelector);
  const service = options?.service;
  const [filesArrayToUpload, setFilesArrayToUpload] = useState<File[]>([]);
  const { handleUploadImagesFiles } = useFileUploadAndMerge({
    service: mergePageService(t),
  });

  const isMultiplyAndMaxCount =
    service?.serviceType === EServiceType.MERGER ||
    (service?.serviceType === EServiceType.CONVERTOR &&
      location?.pathname.includes('/image-to-pdf'));

  const { handleUploadFile } = useFileUploadAndConvert({ service });
  const { handleUploadFile: handleUploadFileNewEditorFlow } =
    useFileUploadAndConverteNewEditorFlow207({ service });
  const { handleUploadFile: handleUploadEditFile } = useFileUploadAndEdit({
    service,
  });
  const { handleUploadFile: handleUploadCompressFile } = useFileUploadAndCompress({ service });
  const { handleUploadFile: handleUploadCompressFileNewEditorFlow } =
    useFileUploadAndCompressNewEditorFlow207({ service });
  const { handleUploadFile: handleUploadSplitFile } = useFileUploadAndSplit({
    service,
  });

  const newEditorFlowAB = useNewEditorFlow();

  const uploadFileCallback = useMemo(
    () => ({
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A]: handleUploadFile,
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B]: handleUploadFileNewEditorFlow,
    }),
    [handleUploadFile, handleUploadFileNewEditorFlow]
  );
  const uploadFileAndCompressCallback = useMemo(
    () => ({
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A]: handleUploadCompressFile,
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B]: handleUploadCompressFileNewEditorFlow,
    }),
    [handleUploadCompressFile, handleUploadCompressFileNewEditorFlow]
  );

  const uploadFileConverterCallback = useMemo(
    () => ({
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A]: (file: any) =>
        dispatch(
          toggleModal({
            visible: true,
            type: EModalTypes.CHOOSE_FORMAT_AND_CONVERT,
            options: { file, service },
          })
        ),
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B]: handleUploadFileNewEditorFlow,
    }),
    [dispatch, handleUploadFileNewEditorFlow, service]
  );

  const [fileData, setFileData] = useState<(File & { uid: string }) | null>(null);
  const [countFiles, setCountFiles] = useState<number>(0);
  let sendNotification = false;

  useDisableScroll();

  const handleBeforeUpload = (
    file: (File & { uid: string }) | null,
    FileList?: File[] & { uid: string }[]
  ) => {
    let isErrorMessage = validateFile(file, t, service?.availableFormats);

    console.log('isErrorMessage--->', isErrorMessage, service?.availableFormats);

    if (mergeDocumentsList?.length + FileList?.length > 15)
      isErrorMessage = t('global.upload_up_to');
    if (FileList?.length && location?.pathname?.includes('/merge-pdf'))
      setCountFiles(FileList?.length);

    if (isErrorMessage || !file) {
      dispatch(toggleModal({ visible: false }));

      if (!sendNotification) {
        sendNotification = true;
        openNotification({
          message: isErrorMessage,
          type: ENotification.ERROR,
        });
        dispatch(sendAnalyticEvent({
          event: 'file_upload_status',
          data: {
            status: 'fail',
            place: 'main',
            errorCode: (file?.size || 0) > 100 * 1024 * 1024 ? 'max-size' : 'wrong-format',
            size: formatFileSize(file?.size || 0),
            accurate_size: (file?.size || 0) / 1000000,
            fileCounter: 1,
            file_format: `.${file?.name?.split('.')?.pop()}`,
            is_validation_error: 'true',
          },
        }));
        if (service?.serviceType === EServiceType.MERGER) {
          dispatch(sendAnalyticEvent({ event: 'complete_merge_error_message' }));
        }
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    // fix drag html elements on site (png, svg)
    const listener = () => {
      handleBeforeUpload(fileData);
    };

    window.addEventListener('drop', listener);

    return () => {
      window.removeEventListener('drop', listener);
    };
  });

  const handleOnDragLeave = (event: any) => {
    if (!event?.relatedTarget) dispatch(toggleModal({ visible: false }));
  };

  useEffect(() => {
    if (location?.pathname?.includes('/merge-pdf')) {
      if (countFiles && mergeDocumentsList?.length === countFiles) {
        dispatch(toggleModal({ visible: false }));
        navigate('/complete-merge');
      }
    }
  }, [navigate, location, mergeDocumentsList, countFiles, dispatch]);

  const processFiles = (file: any) => {
    setFileData(file?.file);
    const filesToProcess = filesArrayToUpload.length > 0 ? filesArrayToUpload : [file.file];

    dispatch(sendAnalyticEvent({
      event: 'upload_ﬁle_tap',
      data: { method: 'drag_and_drop' },
    }));

    if (
      service?.serviceType === EServiceType.CONVERTOR &&
      location?.pathname.includes('/image-to-pdf') &&
      filesArrayToUpload.length > 1
    ) {
      return handleUploadImagesFiles(filesToProcess);
    }

    switch (service?.serviceType) {
      case EServiceType.PDF_CONVERTOR:
        uploadFileConverterCallback[newEditorFlowAB](filesToProcess[0]);
        break;
      case EServiceType.COMPRESSOR:
        uploadFileAndCompressCallback[newEditorFlowAB](filesToProcess[0]);
        break;
      case EServiceType.MERGER:
        filesToProcess.forEach((file) => {
          dispatch(pushMergeDocument({ file, thumbnail: null }));
        });
        break;
      case EServiceType.EDITOR:
        handleUploadEditFile(filesToProcess[0]);
        break;
      case EServiceType.SPLITTER:
        handleUploadSplitFile(filesToProcess[0]);
        break;
      case EServiceType.OCR:
        dispatch(
          toggleModal({
            visible: true,
            type: EModalTypes.CHOOSE_FORMAT_AND_PARSE_TEXT,
            options: { file: filesToProcess[0], service },
          })
        );
        break;
      default:
        uploadFileCallback[newEditorFlowAB](filesToProcess[0]);
    }

    setFilesArrayToUpload([]);
  };

  return (
    <ModalContainer onDragLeave={(event) => handleOnDragLeave(event)}>
      <Upload
        name="file"
        type="drag"
        showUploadList={false}
        multiple={isMultiplyAndMaxCount}
        maxCount={isMultiplyAndMaxCount ? 15 : 1}
        customRequest={processFiles}
        beforeUpload={(file: File & { uid: string }, FileList: File[] & { uid: string }[]) =>
          FileList.length > 1 ? setFilesArrayToUpload(FileList) : handleBeforeUpload(file, FileList)
        }
      >
        <Container>
          <Title>{t('global.drop_your_file_here')}</Title>
        </Container>
      </Upload>
    </ModalContainer>
  );
};

export default DragAndDropModal;
