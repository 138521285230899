export const getFileKeyFromAWSLink = (link: string): string => {
  // Use regular expression to extract the desired part between "amazonaws.com/" and "?"
  const pattern =
    process.env?.REACT_APP_DASHBOARD_URL === 'https://rkmjdbqgla.thebestpdf.com'
      ? /rkmjdbqgla.thebestpdf.com\/(.*?)\?/
      : /thebestpdf.com\/(.*?)\?/;
  const match = link.match(pattern);

  if (!match) {
    throw new Error('File key not found');
  }

  return match[1];
};
