//** Icons */
import buttIcon from 'assets/butt.svg';
import cubeIcon from 'assets/cube.svg';
import aiStarsDarkIcon from 'assets/plans/ai/ai-stars-dark.svg';
import aiStarsIcon from 'assets/plans/ai/ai-stars.svg';
import basicToolsIcon from 'assets/plans/ai/basic-tools-icon.svg';
import chatDarkIcon from 'assets/plans/ai/chat-icon-dark.svg';
import chatIcon from 'assets/plans/ai/chat-icon.svg';
import cloudIcon from 'assets/plans/ai/cloud-icon.svg';
import commentsIcon from 'assets/plans/ai/comments-icon.svg';
import copyIcon from 'assets/plans/ai/copy-icon.svg';
import downloadIcon from 'assets/plans/ai/download-icon.svg';
import mergeIcon from 'assets/plans/ai/merge-icon.svg';
import thunderboltIcon from 'assets/plans/ai/thundeerbolt-icon.svg';
import profileStarIcon from 'assets/profile-star.svg';
import { IPlan, IPlanAI } from 'ts/interfaces/plan/plan';

enum EPlanSlug {
  MONTHLY_0_95 = 'the_best_pdf_monthly_49_95_with_trial_0_95',
  MONTHLY_1_95 = 'the_best_pdf_monthly_49_95_with_trial_1_95',
  YEARLY_199 = 'the_best_pdf_yearly_199_00_no_trial',
  MONTHLY_24_95_NO_TRIAL = 'the_best_pdf_monthly_24_95_without_trial',
  MONTHLY_39_99_WITH_TRIAL_0_95 = 'the_best_pdf_monthly_39_95_with_trial_0_95',
  MONTHLY_39_99_WITH_TRIAL_1_95 = 'the_best_pdf_monthly_39_95_with_trial_1_95',
  MONTHLY_0_95_JPY = 'TheBestPDF_monthly_39_95_in_Japan_with_trial_0_99_JPY',
  MONTHLY_1_95_JPY = 'TheBestPDF_monthly_39_95_in_Japan_with_trial_1_99_JPY',
  YEARLY_199_JPY = 'TheBestPDF_yearly_199_00_in_Japan_no_trial_JPY',
}

export const initialPlans: IPlan[] = [
  {
    id: '6d99aabd-13c0-4d80-b375-bd465933565e',
    name: 'choose_plan_page.plans_list.0.name',
    testBillingName: 'choose_plan_page.plans_list.0.testBillingName',
    testName: 'choose_plan_page.plans_list.0.testName',
    subtitle: 'choose_plan_page.plans_list.0.subtitle',
    slagName: EPlanSlug.MONTHLY_0_95,
    price: 0.95,
    fullPrice: 49.95,
    currencySymbol: '$',
    features: [
      'choose_plan_page.plans_list.0.features.0',
      'choose_plan_page.plans_list.0.features.1',
      'choose_plan_page.plans_list.0.features.2',
    ],
    period: 'choose_plan_page.plans_list.0.period',
    testFeatures: [
      'choose_plan_page.plans_list.0.testFeatures.0',
      'choose_plan_page.plans_list.0.testFeatures.1',
      'choose_plan_page.plans_list.0.testFeatures.2',
      'choose_plan_page.plans_list.0.testFeatures.3',
      'choose_plan_page.plans_list.0.testFeatures.4',
    ],
    testPeriod: '',
    testPeriodShort: '',
    icon: cubeIcon,
    billingCycle: 'month',
    currency: 'USD',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $0.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.plans_list.0.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.0.descriptionPayment',
  },
  {
    id: 'acd77eaf-7762-440d-b633-20feed4b7fd4',
    name: 'choose_plan_page.plans_list.1.name',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingName',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_1_95,
    price: 1.95,
    fullPrice: 49.95,
    features: [
      'choose_plan_page.plans_list.1.features.0',
      'choose_plan_page.plans_list.1.features.1',
      'choose_plan_page.plans_list.1.features.2',
    ],
    period: 'choose_plan_page.plans_list.1.period',
    testFeatures: [
      'choose_plan_page.plans_list.1.testFeatures.0',
      'choose_plan_page.plans_list.1.testFeatures.1',
      'choose_plan_page.plans_list.1.testFeatures.2',
      'choose_plan_page.plans_list.1.testFeatures.3',
      'choose_plan_page.plans_list.1.testFeatures.4',
    ],
    icon: profileStarIcon,
    testPeriod: '',
    testPeriodShort: '',
    mostPopular: true,
    billingCycle: 'month',
    currency: 'USD',
    currencySymbol: '$',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $1.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.plans_list.1.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.1.descriptionPayment',
  },
  {
    id: '01371f0b-70ea-4cbe-8914-0fb9e16962a5',
    name: 'choose_plan_page.plans_list.2.name',
    testBillingName: 'choose_plan_page.plans_list.2.testBillingName',
    testName: 'choose_plan_page.plans_list.2.testName',
    subtitle: 'choose_plan_page.plans_list.2.subtitle',
    slagName: EPlanSlug.YEARLY_199,
    price: 16.58,
    features: [
      'choose_plan_page.plans_list.2.features.0',
      'choose_plan_page.plans_list.2.features.1',
      'choose_plan_page.plans_list.2.features.2',
    ],
    period: 'choose_plan_page.plans_list.2.period',
    testFeatures: [
      'choose_plan_page.plans_list.2.testFeatures.0',
      'choose_plan_page.plans_list.2.testFeatures.1',
      'choose_plan_page.plans_list.2.testFeatures.2',
      'choose_plan_page.plans_list.2.testFeatures.3',
      'choose_plan_page.plans_list.2.testFeatures.4',
    ],
    testPeriod: 'choose_plan_page.plans_list.2.testPeriod',
    testPeriodShort: 'choose_plan_page.plans_list.2.testPeriodShort',
    icon: buttIcon,
    billingCycle: 'year',
    fullPrice: 199.0,
    isYear: true,
    currency: 'USD',
    currencySymbol: '$',
    description:
      'By continuing you agree that you will be automatically charged $199 every year until you cancel in account. Learn more about cancellation and refund policy in',
    descriptionSales: 'choose_plan_page.plans_list.2.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.2.descriptionPayment',
  },
];

export const initialPlansRecurrentPrice: IPlan[] = [
  {
    id: '39b4a5e8-b9a6-4154-b9e5-a5bd723a8057',
    name: 'choose_plan_page.plans_list.0.name',
    testBillingName: 'choose_plan_page.plans_list.0.testBillingName',
    testName: 'choose_plan_page.plans_list.0.testName',
    subtitle: 'choose_plan_page.plans_list.0.subtitle',
    slagName: EPlanSlug.MONTHLY_39_99_WITH_TRIAL_0_95,
    price: 0.95,
    fullPrice: 39.95,
    currencySymbol: '$',
    features: [
      'choose_plan_page.plans_list.0.features.0',
      'choose_plan_page.plans_list.0.features.1',
      'choose_plan_page.plans_list.0.features.2',
    ],
    period: 'choose_plan_page.plans_list.0.period',
    testFeatures: [
      'choose_plan_page.plans_list.0.testFeatures.0',
      'choose_plan_page.plans_list.0.testFeatures.1',
      'choose_plan_page.plans_list.0.testFeatures.2',
      'choose_plan_page.plans_list.0.testFeatures.3',
      'choose_plan_page.plans_list.0.testFeatures.4',
    ],
    testPeriod: '',
    testPeriodShort: '',
    icon: cubeIcon,
    billingCycle: 'month',
    currency: 'USD',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $0.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.plans_list.0.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.0.descriptionPayment',
  },
  {
    id: '82895726-06cc-4e13-984c-c733250dda19',
    name: 'choose_plan_page.plans_list.1.name',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingName',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_39_99_WITH_TRIAL_1_95,
    price: 1.95,
    fullPrice: 39.95,
    features: [
      'choose_plan_page.plans_list.1.features.0',
      'choose_plan_page.plans_list.1.features.1',
      'choose_plan_page.plans_list.1.features.2',
    ],
    period: 'choose_plan_page.plans_list.1.period',
    testFeatures: [
      'choose_plan_page.plans_list.1.testFeatures.0',
      'choose_plan_page.plans_list.1.testFeatures.1',
      'choose_plan_page.plans_list.1.testFeatures.2',
      'choose_plan_page.plans_list.1.testFeatures.3',
      'choose_plan_page.plans_list.1.testFeatures.4',
    ],
    icon: profileStarIcon,
    testPeriod: '',
    testPeriodShort: '',
    mostPopular: true,
    billingCycle: 'month',
    currency: 'USD',
    currencySymbol: '$',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $1.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.plans_list.1.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.1.descriptionPayment',
  },
  {
    id: '01371f0b-70ea-4cbe-8914-0fb9e16962a5',
    name: 'choose_plan_page.plans_list.2.name',
    testBillingName: 'choose_plan_page.plans_list.2.testBillingName',
    testName: 'choose_plan_page.plans_list.2.testName',
    subtitle: 'choose_plan_page.plans_list.2.subtitle',
    slagName: EPlanSlug.YEARLY_199,
    price: 16.58,
    features: [
      'choose_plan_page.plans_list.2.features.0',
      'choose_plan_page.plans_list.2.features.1',
      'choose_plan_page.plans_list.2.features.2',
    ],
    period: 'choose_plan_page.plans_list.2.period',
    testFeatures: [
      'choose_plan_page.plans_list.2.testFeatures.0',
      'choose_plan_page.plans_list.2.testFeatures.1',
      'choose_plan_page.plans_list.2.testFeatures.2',
      'choose_plan_page.plans_list.2.testFeatures.3',
      'choose_plan_page.plans_list.2.testFeatures.4',
    ],
    testPeriod: 'choose_plan_page.plans_list.2.testPeriod',
    testPeriodShort: 'choose_plan_page.plans_list.2.testPeriodShort',
    icon: buttIcon,
    billingCycle: 'year',
    fullPrice: 199.0,
    isYear: true,
    currency: 'USD',
    currencySymbol: '$',
    description:
      'By continuing you agree that you will be automatically charged $199 every year until you cancel in account. Learn more about cancellation and refund policy in',
    descriptionSales: 'choose_plan_page.plans_list.2.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.2.descriptionPayment',
  },
];

export const initialAIPlansRecurrentPrice: IPlanAI[] = [
  {
    id: '39b4a5e8-b9a6-4154-b9e5-a5bd723a8057',
    name: 'choose_plan_page.ai_plans_list.0.name',
    testBillingName: 'choose_plan_page.ai_plans_list.0.testBillingName',
    testName: 'choose_plan_page.ai_plans_list.0.testName',
    subtitle: 'choose_plan_page.ai_plans_list.0.subtitle',
    slagName: EPlanSlug.MONTHLY_39_99_WITH_TRIAL_0_95,
    price: 0.95,
    fullPrice: 39.95,
    currencySymbol: '$',
    features: [
      'choose_plan_page.ai_plans_list.0.features.0',
      'choose_plan_page.ai_plans_list.0.features.1',
      'choose_plan_page.ai_plans_list.0.features.2',
    ],
    period: 'choose_plan_page.ai_plans_list.0.period',
    testFeatures: [
      'choose_plan_page.ai_plans_list.0.testFeatures.0',
      'choose_plan_page.ai_plans_list.0.testFeatures.1',
      'choose_plan_page.ai_plans_list.0.testFeatures.2',
      'choose_plan_page.ai_plans_list.0.testFeatures.3',
      'choose_plan_page.ai_plans_list.0.testFeatures.4',
    ],
    testFeaturesWithIcons: [
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.0', icon: downloadIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.1', icon: copyIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.2', icon: mergeIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.3', icon: cloudIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.4', icon: commentsIcon },
    ],
    testPeriod: '',
    testPeriodShort: '',
    icon: cubeIcon,
    billingCycle: 'month',
    currency: 'USD',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $0.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.ai_plans_list.0.descriptionSales',
    descriptionPayment: 'choose_plan_page.ai_plans_list.0.descriptionPayment',
    featuresDescription: 'choose_plan_page.basic_features',
    pill: {
      icon: basicToolsIcon,
      label: 'choose_plan_page.ai_plans_list.0.subtitle',
    },
    hasAI: false,
  },
  {
    id: '82895726-06cc-4e13-984c-c733250dda19',
    name: 'choose_plan_page.ai_plans_list.1.name',
    testBillingName: 'choose_plan_page.ai_plans_list.1.testBillingName',
    testName: 'choose_plan_page.ai_plans_list.1.testName',
    subtitle: 'choose_plan_page.ai_plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_39_99_WITH_TRIAL_1_95,
    price: 1.95,
    fullPrice: 39.95,
    features: [
      'choose_plan_page.ai_plans_list.1.features.0',
      'choose_plan_page.ai_plans_list.1.features.1',
      'choose_plan_page.ai_plans_list.1.features.2',
    ],
    period: 'choose_plan_page.ai_plans_list.1.period',
    testFeatures: [
      'choose_plan_page.ai_plans_list.1.testFeatures.0',
      'choose_plan_page.ai_plans_list.1.testFeatures.1',
      'choose_plan_page.ai_plans_list.1.testFeatures.2',
      'choose_plan_page.ai_plans_list.1.testFeatures.3',
      'choose_plan_page.ai_plans_list.1.testFeatures.4',
    ],
    testFeaturesWithIcons: [
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.0', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.1', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.2', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.3', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.4', icon: chatIcon },
    ],
    icon: profileStarIcon,
    testPeriod: '',
    testPeriodShort: '',
    mostPopular: true,
    billingCycle: 'month',
    currency: 'USD',
    currencySymbol: '$',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $1.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.ai_plans_list.1.descriptionSales',
    descriptionPayment: 'choose_plan_page.ai_plans_list.1.descriptionPayment',
    featuresDescription: 'choose_plan_page.includes_basic',
    pill: {
      icon: aiStarsIcon,
      label: 'choose_plan_page.ai_plans_list.1.subtitle',
    },
    hasAI: true,
  },
  {
    id: '01371f0b-70ea-4cbe-8914-0fb9e16962a5',
    name: 'choose_plan_page.ai_plans_list.2.name',
    testBillingName: 'choose_plan_page.ai_plans_list.2.testBillingName',
    testName: 'choose_plan_page.ai_plans_list.2.testName',
    subtitle: 'choose_plan_page.ai_plans_list.2.subtitle',
    slagName: EPlanSlug.YEARLY_199,
    price: 16.58,
    features: [
      'choose_plan_page.ai_plans_list.2.features.0',
      'choose_plan_page.ai_plans_list.2.features.1',
      'choose_plan_page.ai_plans_list.2.features.2',
    ],
    period: 'choose_plan_page.ai_plans_list.2.period',
    testFeatures: [
      'choose_plan_page.ai_plans_list.2.testFeatures.0',
      'choose_plan_page.ai_plans_list.2.testFeatures.1',
      'choose_plan_page.ai_plans_list.2.testFeatures.2',
      'choose_plan_page.ai_plans_list.2.testFeatures.3',
      'choose_plan_page.ai_plans_list.2.testFeatures.4',
    ],
    testFeaturesWithIcons: [
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.0', icon: chatDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.1', icon: aiStarsDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.2', icon: aiStarsDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.3', icon: aiStarsDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.4', icon: aiStarsDarkIcon },
    ],
    testPeriod: 'choose_plan_page.ai_plans_list.2.testPeriod',
    testPeriodShort: 'choose_plan_page.ai_plans_list.2.testPeriodShort',
    icon: buttIcon,
    billingCycle: 'year',
    fullPrice: 199.0,
    isYear: true,
    currency: 'USD',
    currencySymbol: '$',
    description:
      'By continuing you agree that you will be automatically charged $199 every year until you cancel in account. Learn more about cancellation and refund policy in',
    descriptionSales: 'choose_plan_page.ai_plans_list.2.descriptionSales',
    descriptionPayment: 'choose_plan_page.ai_plans_list.2.descriptionPayment',
    featuresDescription: 'choose_plan_page.includes_basic',
    pill: {
      icon: thunderboltIcon,
      label: 'choose_plan_page.ai_plans_list.2.subtitle',
    },
    hasAI: true,
  },
];

export const initialJapanesePlans: IPlan[] = [
  {
    id: '862db0b2-7244-44d5-946d-2d1f2226de46',
    name: 'choose_plan_page.plans_list.0.name',
    testBillingName: 'choose_plan_page.plans_list.0.testBillingName',
    testName: 'choose_plan_page.plans_list.0.testName',
    subtitle: 'choose_plan_page.plans_list.0.subtitle',
    slagName: EPlanSlug.MONTHLY_0_95_JPY,
    price: 0.95,
    fullPrice: 39.95,
    currencySymbol: '¥',
    features: [
      'choose_plan_page.plans_list.0.features.0',
      'choose_plan_page.plans_list.0.features.1',
      'choose_plan_page.plans_list.0.features.2',
    ],
    period: 'choose_plan_page.plans_list.0.period',
    testFeatures: [
      'choose_plan_page.plans_list.0.testFeatures.0',
      'choose_plan_page.plans_list.0.testFeatures.1',
      'choose_plan_page.plans_list.0.testFeatures.2',
      'choose_plan_page.plans_list.0.testFeatures.3',
      'choose_plan_page.plans_list.0.testFeatures.4',
    ],
    testPeriod: '',
    testPeriodShort: '',
    icon: cubeIcon,
    billingCycle: 'month',
    currency: 'JPY',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $0.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.plans_list.0.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.0.descriptionPayment',
  },
  {
    id: '15dc5927-67da-42cd-a041-5c2a05e577c5',
    name: 'choose_plan_page.plans_list.1.name',
    testBillingName: 'choose_plan_page.plans_list.1.testBillingName',
    testName: 'choose_plan_page.plans_list.1.testName',
    subtitle: 'choose_plan_page.plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_0_95_JPY,
    price: 1.95,
    fullPrice: 39.95,
    features: [
      'choose_plan_page.plans_list.1.features.0',
      'choose_plan_page.plans_list.1.features.1',
      'choose_plan_page.plans_list.1.features.2',
    ],
    period: 'choose_plan_page.plans_list.1.period',
    testFeatures: [
      'choose_plan_page.plans_list.1.testFeatures.0',
      'choose_plan_page.plans_list.1.testFeatures.1',
      'choose_plan_page.plans_list.1.testFeatures.2',
      'choose_plan_page.plans_list.1.testFeatures.3',
      'choose_plan_page.plans_list.1.testFeatures.4',
    ],
    icon: profileStarIcon,
    testPeriod: '',
    testPeriodShort: '',
    mostPopular: true,
    billingCycle: 'month',
    currency: 'JPY',
    currencySymbol: '¥',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $1.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.plans_list.1.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.1.descriptionPayment',
  },
  {
    id: '42c573ba-7a76-4a40-8855-1b28f4934bf4',
    name: 'choose_plan_page.plans_list.2.name',
    testBillingName: 'choose_plan_page.plans_list.2.testBillingName',
    testName: 'choose_plan_page.plans_list.2.testName',
    subtitle: 'choose_plan_page.plans_list.2.subtitle',
    slagName: EPlanSlug.YEARLY_199_JPY,
    price: 16.58,
    features: [
      'choose_plan_page.plans_list.2.features.0',
      'choose_plan_page.plans_list.2.features.1',
      'choose_plan_page.plans_list.2.features.2',
    ],
    period: 'choose_plan_page.plans_list.2.period',
    testFeatures: [
      'choose_plan_page.plans_list.2.testFeatures.0',
      'choose_plan_page.plans_list.2.testFeatures.1',
      'choose_plan_page.plans_list.2.testFeatures.2',
      'choose_plan_page.plans_list.2.testFeatures.3',
      'choose_plan_page.plans_list.2.testFeatures.4',
    ],
    testPeriod: 'choose_plan_page.plans_list.2.testPeriod',
    testPeriodShort: 'choose_plan_page.plans_list.2.testPeriodShort',
    icon: buttIcon,
    billingCycle: 'year',
    fullPrice: 199.0,
    isYear: true,
    currency: 'JPY',
    currencySymbol: '¥',
    description:
      'By continuing you agree that you will be automatically charged $199 every year until you cancel in account. Learn more about cancellation and refund policy in',
    descriptionSales: 'choose_plan_page.plans_list.2.descriptionSales',
    descriptionPayment: 'choose_plan_page.plans_list.2.descriptionPayment',
  },
];

export const initialAIJapanesePlans: IPlanAI[] = [
  {
    id: '862db0b2-7244-44d5-946d-2d1f2226de46',
    name: 'choose_plan_page.ai_plans_list.0.name',
    testBillingName: 'choose_plan_page.ai_plans_list.0.testBillingName',
    testName: 'choose_plan_page.ai_plans_list.0.testName',
    subtitle: 'choose_plan_page.ai_plans_list.0.subtitle',
    slagName: EPlanSlug.MONTHLY_0_95_JPY,
    price: 0.95,
    fullPrice: 39.95,
    currencySymbol: '¥',
    features: [
      'choose_plan_page.ai_plans_list.0.features.0',
      'choose_plan_page.ai_plans_list.0.features.1',
      'choose_plan_page.ai_plans_list.0.features.2',
    ],
    period: 'choose_plan_page.ai_plans_list.0.period',
    testFeatures: [
      'choose_plan_page.ai_plans_list.0.testFeatures.0',
      'choose_plan_page.ai_plans_list.0.testFeatures.1',
      'choose_plan_page.ai_plans_list.0.testFeatures.2',
      'choose_plan_page.ai_plans_list.0.testFeatures.3',
      'choose_plan_page.ai_plans_list.0.testFeatures.4',
    ],
    testFeaturesWithIcons: [
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.0', icon: downloadIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.1', icon: copyIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.2', icon: mergeIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.3', icon: cloudIcon },
      { label: 'choose_plan_page.ai_plans_list.0.testFeatures.4', icon: commentsIcon },
    ],
    testPeriod: '',
    testPeriodShort: '',
    icon: cubeIcon,
    billingCycle: 'month',
    currency: 'JPY',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $0.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.ai_plans_list.0.descriptionSales',
    descriptionPayment: 'choose_plan_page.ai_plans_list.0.descriptionPayment',
    featuresDescription: 'choose_plan_page.basic_features',
    pill: {
      icon: basicToolsIcon,
      label: 'choose_plan_page.ai_plans_list.0.subtitle',
    },
    hasAI: false,
  },
  {
    id: '15dc5927-67da-42cd-a041-5c2a05e577c5',
    name: 'choose_plan_page.ai_plans_list.1.name',
    testBillingName: 'choose_plan_page.ai_plans_list.1.testBillingName',
    testName: 'choose_plan_page.ai_plans_list.1.testName',
    subtitle: 'choose_plan_page.ai_plans_list.1.subtitle',
    slagName: EPlanSlug.MONTHLY_0_95_JPY,
    price: 1.95,
    fullPrice: 39.95,
    features: [
      'choose_plan_page.ai_plans_list.1.features.0',
      'choose_plan_page.ai_plans_list.1.features.1',
      'choose_plan_page.ai_plans_list.1.features.2',
    ],
    period: 'choose_plan_page.ai_plans_list.1.period',
    testFeatures: [
      'choose_plan_page.ai_plans_list.1.testFeatures.0',
      'choose_plan_page.ai_plans_list.1.testFeatures.1',
      'choose_plan_page.ai_plans_list.1.testFeatures.2',
      'choose_plan_page.ai_plans_list.1.testFeatures.3',
      'choose_plan_page.ai_plans_list.1.testFeatures.4',
    ],
    testFeaturesWithIcons: [
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.0', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.1', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.2', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.3', icon: aiStarsIcon },
      { label: 'choose_plan_page.ai_plans_list.1.testFeatures.4', icon: chatIcon },
    ],
    icon: profileStarIcon,
    testPeriod: '',
    testPeriodShort: '',
    mostPopular: true,
    billingCycle: 'month',
    currency: 'JPY',
    currencySymbol: '¥',
    description:
      "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the 7-Days trial for $1.95, you will be automatically charged $49.95 every month until you cancel in account. Learn more about cancellation and refund policy in",
    descriptionSales: 'choose_plan_page.ai_plans_list.1.descriptionSales',
    descriptionPayment: 'choose_plan_page.ai_plans_list.1.descriptionPayment',
    featuresDescription: 'choose_plan_page.includes_basic',
    pill: {
      icon: aiStarsIcon,
      label: 'choose_plan_page.ai_plans_list.1.subtitle',
    },
    hasAI: true,
  },
  {
    id: '42c573ba-7a76-4a40-8855-1b28f4934bf4',
    name: 'choose_plan_page.ai_plans_list.2.name',
    testBillingName: 'choose_plan_page.ai_plans_list.2.testBillingName',
    testName: 'choose_plan_page.ai_plans_list.2.testName',
    subtitle: 'choose_plan_page.ai_plans_list.2.subtitle',
    slagName: EPlanSlug.YEARLY_199_JPY,
    price: 16.58,
    features: [
      'choose_plan_page.ai_plans_list.2.features.0',
      'choose_plan_page.ai_plans_list.2.features.1',
      'choose_plan_page.ai_plans_list.2.features.2',
    ],
    period: 'choose_plan_page.ai_plans_list.2.period',
    testFeatures: [
      'choose_plan_page.ai_plans_list.2.testFeatures.0',
      'choose_plan_page.ai_plans_list.2.testFeatures.1',
      'choose_plan_page.ai_plans_list.2.testFeatures.2',
      'choose_plan_page.ai_plans_list.2.testFeatures.3',
      'choose_plan_page.ai_plans_list.2.testFeatures.4',
    ],
    testFeaturesWithIcons: [
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.0', icon: aiStarsDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.1', icon: aiStarsDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.2', icon: aiStarsDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.3', icon: aiStarsDarkIcon },
      { label: 'choose_plan_page.ai_plans_list.2.testFeatures.4', icon: chatDarkIcon },
    ],
    testPeriod: 'choose_plan_page.ai_plans_list.2.testPeriod',
    testPeriodShort: 'choose_plan_page.ai_plans_list.2.testPeriodShort',
    icon: buttIcon,
    billingCycle: 'year',
    fullPrice: 199.0,
    isYear: true,
    currency: 'JPY',
    currencySymbol: '¥',
    description:
      'By continuing you agree that you will be automatically charged $199 every year until you cancel in account. Learn more about cancellation and refund policy in',
    descriptionSales: 'choose_plan_page.ai_plans_list.2.descriptionSales',
    descriptionPayment: 'choose_plan_page.ai_plans_list.2.descriptionPayment',
    featuresDescription: 'choose_plan_page.includes_basic',
    pill: {
      icon: thunderboltIcon,
      label: 'choose_plan_page.ai_plans_list.2.subtitle',
    },
    hasAI: true,
  },
];
