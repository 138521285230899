import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 20px;
  background: var(--White-White, #fff);
  /* Shadow 2 */
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  min-width: 343px;
  width: 550px;
  max-height: 353px;
  height: 100%;
  padding: var(--Radius-l, 20px);
  gap: 35px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
    max-height: fit-content;
    height: auto;
    margin: 0 15px;
  }
`;

export const Description = styled.div`
  color: var(--Text-color-tittle, #212e45);
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 32.5px */
  max-width: 400px;

  @media (max-width: 760px) {
    font-size: 16px;
    line-height: 120%; /* 19.2px */

    font-style: normal;
  }
`;

export const RocketImage = styled.img`
  @media (max-width: 760px) {
    width: 61.262px;
    height: var(--Radius-4xl, 80px);
  }
`;
