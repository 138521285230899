import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { IService } from 'ts/interfaces/services/service';

export const setRecentlyUsedActions = (service: IService) => {
  const recentlyUsedActions = getJsonFomStorage('recentlyUsedActions');

  const newAction = { name: service.nameKey, path: service.path };

  if (recentlyUsedActions?.length) {
    // check if the action is not in the list
    if (recentlyUsedActions?.findIndex((action: any) => action.name === newAction.name) === -1) {
      recentlyUsedActions.unshift(newAction);
    }

    return localStorage.setItem(
      'recentlyUsedActions',
      JSON.stringify(
        // only last 4 actions
        recentlyUsedActions.length >= 5 ? recentlyUsedActions.slice(0, 4) : recentlyUsedActions
      )
    );
  } else localStorage.setItem('recentlyUsedActions', JSON.stringify([newAction]));
};
