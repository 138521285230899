import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useBasePath } from 'hooks/useCustomNavigate';
//** Selectors */
import { selectedPlanSelector } from 'data/selectors/subscriptions';

//** Components */

/** Types */
import { IPlan } from 'ts/interfaces/plan/plan';

/** Actions */
import { setSelectedPlan } from 'data/actions/subscriptions';

//** Icons */
import checkMarkIcon from 'assets/checkmark.svg';

//** Styles */
import SelectPlanFilePreview from 'components/common/filePreview/FilePreview';
import PlanItemTestB from 'components/planItem/tests/abc_modal_vs_united_payment_2_0_7/planItemTestB';
import { convertDocument } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import useKeyDownPress from 'hooks/useKeyPress';
import { usePlans } from 'hooks/usePlans';
import queryString from 'query-string';
import { EModalTypes } from 'ts/enums/modal.types';
import {
  Container,
  ContinueButton,
  Description,
  DialogLeftContainer,
  DialogPreviewContainer,
  DialogTitle,
  FeatureColumn,
  FeatureIcon,
  FeatureItem,
  FeaturesContainer,
  FilePreviewTitle,
  PlansContainer,
} from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const SelectPlanModalContent: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const plans = usePlans();
  const basePath = useBasePath();

  useEffect(() => {
    dispatch(sendAnalyticEvent({ event: 'select_plan_view', data: { screen_config_name: 'sales_modal' } }));
  }, [dispatch]);

  const handleSelectPlan = ({
    plan,
  }: {
    plan: IPlan;
    route?: string;
    place: 'planTab' | 'button';
  }) => {
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
    dispatch(setSelectedPlan(plan));
    dispatch(sendAnalyticEvent({
      event: 'choose_plan_tap',
      data: {
        productId: plan?.slagName || plan?.id,
        currency: plan?.currency,
        value: plan?.fullPrice,
      },
    }));
  };

  useEffect(() => {
    if (!selectedPlan) {
      dispatch(setSelectedPlan(plans[1] || plans[0]));
    }
  }, [dispatch, plans, selectedPlan]);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const dataToConvert = localStorage.getItem('dataToConvert');

    if (parsed?.['upload-file'] && dataToConvert) {
      try {
        const data = JSON.parse(dataToConvert);
        if (data?.file) convertDocument(data?.file);
      } catch (err) {}
    }
  }, []); // eslint-disable-line

  useKeyDownPress({
    targetKey: 'Enter',
    onKeyPressDown: () => {
      handleSubmit();
    },
  });

  const handleSubTermClick = () => {
    dispatch(sendAnalyticEvent({ event: 'subscription_terms_tap' }));
  };

  const handleSubmit = () => {
    dispatch(toggleModal({ type: EModalTypes.PAYMENT_MODAL, visible: true }));
  };

  return (
    <Container>
      <DialogLeftContainer>
        <DialogTitle>{t('choose_plan_page.select_a_plan_to_download_your_file')}</DialogTitle>

        <PlansContainer>
          {plans?.map((plan: IPlan, index: number) => (
            <PlanItemTestB
              handleSelectPlan={handleSelectPlan}
              key={`plan-item-${index + 1}`}
              planData={plan}
            />
          ))}
        </PlansContainer>
        <FeaturesContainer>
          <FeatureColumn>
            {selectedPlan?.testFeatures.slice(0, 2).map((feature: any, index: number) => (
              <FeatureItem key={`plan-feature-${index + 1}`}>
                <FeatureIcon src={checkMarkIcon} />
                {t(feature)}
              </FeatureItem>
            ))}
          </FeatureColumn>
          <FeatureColumn>
            {selectedPlan?.testFeatures.slice(2).map((feature: any, index: number) => (
              <FeatureItem key={`plan-feature-${index + 1}`}>
                <FeatureIcon src={checkMarkIcon} />
                {t(feature)}
              </FeatureItem>
            ))}
          </FeatureColumn>
        </FeaturesContainer>
        <ContinueButton type="primary" onClick={() => handleSubmit()}>
          {t('global.continue')}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2813 11.0312 17.2813C11.2187 17.2813 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2188 18.2812 9.78125 18 9.5Z"
              fill="white"
            />
          </svg>
        </ContinueButton>
        <Description>
          {t(selectedPlan?.descriptionPayment, {
            currencySymbol: selectedPlan?.currencySymbol,
            fullPrice: selectedPlan?.fullPrice,
          })}
          {` `}
          <a
            target="_blank"
            onClick={handleSubTermClick}
            href={`${process.env.REACT_APP_DASHBOARD_URL}${basePath}/subscription-terms`}
            rel="noreferrer"
          >
            {t('global.subscription_terms')}
          </a>
          .
        </Description>
      </DialogLeftContainer>
      <DialogPreviewContainer>
        <FilePreviewTitle>{t('checkout_page.your_document_is_ready')}</FilePreviewTitle>
        <SelectPlanFilePreview />
      </DialogPreviewContainer>
    </Container>
  );
};

export default SelectPlanModalContent;
