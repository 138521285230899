import { StyleProvider } from '@ant-design/cssinjs';
import { FC, lazy, Suspense, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';

/** Actions */
import { getPlans } from 'data/actions/subscriptions';
import { createAnonymousUser, getUser, getUserCountry } from 'data/actions/user';

/** Selectors */
import { getLoadingSelector } from 'data/selectors/loading';
import { isUserAuthenticated } from 'data/selectors/user';

/** Providers */
import GrowthBookOwnProvider from 'providers/growthBookProvider';

/** Components */
import CookiesBar from 'components/cookiesBar';
import ModalsController from 'components/modalsController';

/** Helpers */
import { editorServicesList, mainPageService, servicesList } from 'helpers/servicesList';

/** Interfaces */
import { CookiesBarContainer } from 'sections/hero/styles';

/** Styles */
import { GlobalStyle } from 'components/common/styles';
import './styles.css';

/** Hooks */
import useClarity from 'hooks/useClarity';
import useResetPassword from 'hooks/useResetPassword';

/** Services */
import { Amplitude } from 'services/analytics/amplitude';

/** Use cases */
import { useEmailModalTestValue } from 'hooks/growthBook/useEmailModalTestValue';
import { storeMarketingValues } from 'utils/storeMarketingValues';
// import { convertingEvent } from 'data/actions/documents';

import { useNewEditorFlow } from 'hooks/growthBook/useNewEditorFlow';
import { useSalesWithAI } from 'hooks/growthBook/useSalesWithAI';
import { PathLocaleProvider } from 'providers/pathLocaleProvider';
import { LocalStorageProperties } from 'ts/enums/growthbook';
import { IService } from 'ts/interfaces/services/service';
import { DEFAULT_LANGUAGE, loadLocalesDayJs } from './i18n';
import { getRedirectPath } from 'utils/redirectPath';

/** Layouts */
const MainLayout = lazy(() => import('layouts/main'));
const SubscriptionCancelLayout = lazy(() => import('layouts/subscriptionCancel'));

/** Pages */
const DashboardPage = lazy(() => import('pages/dashboard'));
const NotFoundPage = lazy(() => import('pages/404'));
const AboutUsPage = lazy(() => import('pages/aboutUs'));
const CheckoutPage = lazy(() => import('pages/checkout'));
const ContactUsPage = lazy(() => import('pages/contactUs'));
const PDFEditorPage = lazy(() => import('pages/editor'));
const MainPage = lazy(() => import('pages/main'));
const MergePDFPage = lazy(() => import('pages/mergePdf'));
const MoneyBackPolicyPage = lazy(() => import('pages/moneyBackPolicy'));
const PaymentSuccess = lazy(() => import('pages/paymentSuccess'));
const PricingPage = lazy(() => import('pages/pricing'));
const PrivacyPolicyPage = lazy(() => import('pages/privacyPolicy'));
const RedirectPage = lazy(() => import('pages/redirect'));
const ServicePage = lazy(() => import('pages/service'));
const ServicePageNewEditorFlow207 = lazy(() => import('pages/service/ab_new_editor_flow_2_0_7'));
const SettingsPage = lazy(() => import('pages/settings'));
const SubCancelConfirmationPage = lazy(() => import('pages/subCancelConfirmation'));
const SubscriptionOfferPage = lazy(() => import('pages/subscriptionOffer'));
const SubscriptionTermsPage = lazy(() => import('pages/subscriptionTerms'));
const TermsOfConditionsPage = lazy(() => import('pages/termsOfConditions'));
// const ServicePageTw = lazy(() => import('pages/service/tw'));

const RouterContainer: FC = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isLoading = useSelector(getLoadingSelector('get_user'));
  const isAuth = useSelector(isUserAuthenticated);
  const newEditorFlowAB = useNewEditorFlow();

  useSalesWithAI();

  const getServicePageComponent = useCallback(
    (item: IService) => ({
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_A]: <ServicePage service={item} />,
      [LocalStorageProperties.NEW_EDITOR_FLOW_TEST_B]: (
        <ServicePageNewEditorFlow207 service={item} />
      ),
    }),
    []
  );

  useResetPassword();
  useClarity();

  useEmailModalTestValue();

  useEffect(() => {
    dispatch(getUserCountry());
    dispatch(getPlans());

    const onFailed = () => {
      dispatch(
        createAnonymousUser(() => {
          dispatch(getUserCountry());
          dispatch(getUser({}));
        })
      );
    };
    dispatch(getUser({ onFailed }));
  }, [dispatch]);

  useEffect(() => {
    Amplitude.init();
    storeMarketingValues();
    loadLocalesDayJs();
  }, []);

  useEffect(() => {
    Amplitude.updateUser({ key: 'language', value: i18n.language || DEFAULT_LANGUAGE });
  }, [i18n?.language]);

  return (
    <Router>
      <StyleProvider hashPriority="high">
        <PathLocaleProvider>
          <Suspense>
            <Routes>
              <Route path=":language?">
                {/* Authorized user routes */}
                {isAuth && (
                  <>
                    <Route path={`app`} element={<MainLayout />}>
                      <Route index path="dashboard" element={<DashboardPage />} />
                      <Route path="settings" element={<SettingsPage />} />
                      <Route path="subscription-offer" element={<SubscriptionOfferPage />} />
                    </Route>
                    <Route path={`app`} element={<SubscriptionCancelLayout />}>
                      <Route
                        path="subscription-cancel-confirmation"
                        element={<SubCancelConfirmationPage />}
                      />
                    </Route>
                  </>
                )}
                {/* Unauthorized user redirect routes */}
                {(!isAuth && !isLoading) && (
                    <Route path={`app/*`} element={
                      <Navigate to={`/${getRedirectPath(i18n?.language)}`} replace />
                    } />
                )}
                {/* Start tools list */}
                {servicesList(t).map((item: IService, index) => (
                  <Route
                    key={`route-${item.path}-${index + 1}`}
                    path={`${item.path.replace('/', '')}`}
                    element={getServicePageComponent(item)[newEditorFlowAB]}
                  />
                ))}

                {editorServicesList(t).map((item: IService, index) => (
                  <Route
                    key={`route-${item.path}-${index + 1}`}
                    path={`${item.path.replace('/', '')}`}
                    element={getServicePageComponent(item)[newEditorFlowAB]}
                  />
                ))}
                {/* End tools list */}

                <Route path={``} element={<MainPage service={mainPageService(t)} />} />
                <Route path={'redirect'} element={<RedirectPage />} />
                <Route path={`redirect`} element={<RedirectPage />} />
                <Route path={`complete-merge`} element={<MergePDFPage />} />
                <Route path={`editor`} element={<PDFEditorPage />} />
                <Route path={`contact-us`} element={<ContactUsPage />} />
                <Route path={`about-us`} element={<AboutUsPage />} />
                <Route
                  path={`reset-password`}
                  element={<MainPage service={mainPageService(t)} />}
                />
                <Route path={`choose-plan`} element={<PricingPage />} />
                <Route path={`checkout`} element={<CheckoutPage />} />
                <Route path={`checkout/success`} element={<PaymentSuccess />} />
                <Route path={`subscription-terms`} element={<SubscriptionTermsPage />} />
                <Route path={`terms`} element={<TermsOfConditionsPage />} />
                <Route path={`money-back-policy`} element={<MoneyBackPolicyPage />} />
                <Route path={`privacy-policy`} element={<PrivacyPolicyPage />} />
                {!isLoading && <Route path="*" element={<NotFoundPage />} />}
              </Route>
            </Routes>
          </Suspense>
        </PathLocaleProvider>
        <ModalsController />
        <CookiesBarContainer>
          <CookiesBar />
        </CookiesBarContainer>
      </StyleProvider>
    </Router>
  );
};

const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      {/* <GlobalFonts /> */}
      <GrowthBookOwnProvider>
        <RouterContainer />
      </GrowthBookOwnProvider>
    </>
  );
};

export default App;
