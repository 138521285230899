import axios from 'axios';
import { appStorage } from 'utils/appStorage';

export interface StoreMetaPayload {
  source?: string;
  campaign?: string;
  creative?: string;
  refer_url?: string;
}

const LSMetaKey = 'meta';
const apiHost = process.env.REACT_APP_API_URL;

export const saveLocalUserMeta = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const data: StoreMetaPayload = {};

  if (params['utm_source']) {
    data.source = params['utm_source'];
  }

  if (params['utm_campaign']) {
    data.campaign = params['utm_campaign'];
  }

  if (params['creative']) {
    data.creative = params['creative'];
  }

  if (params['refer_url']) {
    data.refer_url = params['refer_url'];
  }

  if (Object.keys(data).length > 0) {
    appStorage.setItem(LSMetaKey, JSON.stringify(data));
  }
};

const LSMetaStoredKey = 'metaStored';
export const updateUserMeta = async (): Promise<void> => {
  const metaValue = appStorage.getItem(LSMetaKey);
  const isStored = appStorage.getItem(LSMetaStoredKey);

  if (!metaValue || isStored) return;

  await axios.post(`${apiHost}/user/meta`, JSON.parse(metaValue));
  appStorage.setItem(LSMetaStoredKey, 'true');
  appStorage.removeItem(LSMetaKey);
};
