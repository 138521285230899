import { CommonTextH2, CommonTextH5 } from 'components/common/styles';
import styled from 'styled-components';

export const Container = styled.div<{ current?: string; mostPopular?: string }>`
  max-width: 588px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Primary-white, #fff);
  /* box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08); */
  border: ${(props) => (props?.current === 'true' ? '2px solid #3758F9' : '2px solid #DFE4EA')};
  cursor: pointer;
`;

export const PlanContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  align-self: stretch;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-end;

  @media (max-width: 760px) {
    justify-content: flex-start;
  }
`;

export const Currency = styled.span<{ current?: string }>`
  color: ${(props) => (props?.current === 'true' ? '#212E45' : '#4C5E7F')};
  /* Desktop/Heading/H3 */

  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  white-space: nowrap;
  display: flex;
  gap: 3px;
`;

export const Price = styled(CommonTextH2)<{ current?: string }>`
  color: ${(props) => (props?.current === 'true' ? '#212E45' : '#4C5E7F')};

  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const PlanTitle = styled(CommonTextH5)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--Radius-l, 20px);

  span {
    color: var(--Text-color-tittle, #212e45);
    /* Desktop/Heading/H4 */

    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 29.9px */
  }

  img {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 760px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const Period = styled.span`
  color: var(--Text-color-subtitle, #8496ba);

  /* Desktop/Small Body/Reg */

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  margin-left: 5px;
  width: max-content;

  @media (max-width: 760px) {
    display: none;
  }
`;

export const MostPopular = styled.div`
  max-width: fit-content;
  padding: 3px 5px;
  border-radius: var(--Radius-xs, 5px);
  background: var(--Green-Green-light-7, #00b286);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Radius-s, 10px);
  align-self: stretch;
  color: var(--Blue-white, #fff);

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 10px;
    min-width: 100px;
    height: 23px;
  }

  @media (max-width: 760px) {
    margin-bottom: 10px;
  }
`;

export const MostPopularContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -6px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 760px) {
    top: 0;
    position: relative;
    justify-content: flex-start;
    right: 0;
  }
`;

export const PlanHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const PlanTitleContainer = styled.div<{ current?: string }>`
  color: ${(props) => (props?.current === 'true' ? '#212E45' : '#4C5E7F')};

  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: max-content;
  text-align: left;
  margin-bottom: 2px;

  span {
    color: ${(props) => (props?.current === 'true' ? '#212E45' : '#4C5E7F')};
  }
`;
