import { appStorage } from './appStorage';

const PREV_PATH_KEY = 'prevPath';

export const savePrevPath = (path: string) => {
  const woSlash = path.replace('/', '');

  appStorage.setItem(PREV_PATH_KEY, woSlash);
};

export const getPrevPage = () => {
  return appStorage.getItem(PREV_PATH_KEY);
};
