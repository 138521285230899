import loadingIcon from 'assets/loading.svg';
import { FC } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ButtonProps } from 'ts/types/button.props';
import { ButtonContainer, LinkButtonContainer } from './styles';

const CommonButton: FC<ButtonProps | any> = ({
  type = 'common',
  children,
  onClick,
  style,
  disabled,
  loading,
  size,
  ...props
}) => {
  return type === 'link' ? (
    <LinkButtonContainer {...props} onClick={onClick} style={style} disabled={disabled || loading}>
      {loading ? <LazyLoadImage src={loadingIcon} width={20} alt={'loading'} /> : children}
    </LinkButtonContainer>
  ) : (
    <ButtonContainer
      {...props}
      onClick={onClick}
      type={type}
      style={style}
      disabled={disabled || loading}
      size={size}
    >
      {loading ? <LazyLoadImage src={loadingIcon} width={20} alt={'loading'} /> : children}
    </ButtonContainer>
  );
};

export default CommonButton;
