import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectedPlanSelector } from 'data/selectors/subscriptions';
import { userCurrencySelector } from 'data/selectors/user';

//** Types */
import { IPlan } from 'ts/interfaces/plan/plan';

//** Icons */
import radioButtonCheckPath from 'assets/radiobutton-check.svg';
import radioButtonPath from 'assets/radiobutton.svg';

//** Styles */
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  Container,
  Currency,
  MostPopular,
  MostPopularContainer,
  Period,
  PlanContentWrapper,
  PlanHeader,
  PlanTitle,
  PlanTitleContainer,
  Price,
  PriceContainer,
} from './styles';

const PlanItemTestB: FC<{ planData: IPlan; handleSelectPlan?: unknown }> = ({
  planData,
  handleSelectPlan,
}) => {
  const { t } = useTranslation();
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const userCurrency = useSelector(userCurrencySelector);
  const handleChoosePlan = (place: string, route?: string) => {
    if (typeof handleSelectPlan === 'function') handleSelectPlan({ plan: planData, route, place });
  };

  return (
    <Container
      mostPopular={`${planData?.mostPopular}`}
      current={(selectedPlan?.name === planData?.name).toString()}
      onClick={() => handleChoosePlan('planTab')}
    >
      <PlanContentWrapper>
        <PlanHeader>
          <PlanTitle>
            <LazyLoadImage
              src={selectedPlan?.name === planData?.name ? radioButtonCheckPath : radioButtonPath}
              alt=""
            />
            <PlanTitleContainer current={(selectedPlan?.name === planData?.name).toString()}>
              <MostPopularContainer>
                {planData.mostPopular && <MostPopular>{t('global.most_popular')}</MostPopular>}
              </MostPopularContainer>
              {planData?.testBillingName && <span>{t(planData?.testBillingName)} </span>}
              {planData?.testName && <span>{t(planData?.testName)}</span>}
            </PlanTitleContainer>
          </PlanTitle>
        </PlanHeader>

        <PriceContainer>
          <Price current={(selectedPlan?.name === planData?.name).toString()}>
            <Currency current={(selectedPlan?.name === planData?.name).toString()}>
              {planData?.currencySymbol || userCurrency} {planData.price}
            </Currency>
            {planData.testPeriod && <Period>{t(planData.testPeriod || '')}</Period>}
          </Price>
        </PriceContainer>
      </PlanContentWrapper>
    </Container>
  );
};

export default PlanItemTestB;
