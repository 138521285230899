import fileTypePDF from 'assets/formatsModal/file-type-pdf.svg';
import fileTypeIMG from 'assets/formatsModal/file-type-img.svg';
import fileTypePPTX from 'assets/formatsModal/file-type-pptx.svg';
import fileTypeWord from 'assets/formatsModal/file-type-word.svg';
import fileTypeExel from 'assets/formatsModal/file-type-exel.svg';

export const formatsList = [
  {
    label: 'PDF',
    icon: fileTypePDF,
    to: 'PDF',
    format: '.pdf',
  },
  {
    label: 'Excel',
    icon: fileTypeExel,
    to: 'XLS',
    format: '.xls',
  },
  {
    label: 'PNG',
    icon: fileTypeIMG,
    to: 'PNG',
    format: '.png',
  },
  {
    label: 'JPG',
    to: 'JPG',
    icon: fileTypeIMG,
    format: '.jpg',
  },
  {
    label: 'Word',
    to: 'DOCX',
    icon: fileTypeWord,
    format: '.docx',
  },
  {
    label: 'PPTX',
    to: 'PPTX',
    icon: fileTypePPTX,
    format: '.pptx',
  },
];
