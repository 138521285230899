import checkMarkIcon from 'assets/checkmark.svg';
import LockedIcon from 'assets/locked-alt.svg';
import usePaypalScript from 'components/checkout/hooks/usePaypalScript';
import { toggleModal } from 'data/actions/modals';
import { initAlternativeSubscription, initPaymentCard } from 'data/actions/subscriptions';
import { selectedPlanSelector } from 'data/selectors/subscriptions';
import { userCurrencySelector, userEmailSelector } from 'data/selectors/user';
import { getJsonFomStorage } from 'helpers/localStorageHelper';
import { useBasePath } from 'hooks/useCustomNavigate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { EModalTypes } from 'ts/enums/modal.types';
import { PaymentMethod } from 'ts/interfaces/order/order';
import PaymentForm from './paymentForm';
import {
  AdditionalPaymentInfo,
  Container,
  Description,
  FeatureIcon,
  FeatureItem,
  FeaturesContainer,
  GoBackButton,
  NortonContainer,
  Price,
  PriceContainer,
  SelectedPlanData,
  SelectedPlanName,
} from './styles';
import { sendAnalyticEvent } from 'data/actions/analytics';

const PaymentFormTestB = () => {
  const [merchantData, setMerchantData] = useState<any>(null);
  const [paypalScript, setPaypalScript] = useState<string | null>(null);
  const selectedPlan = useSelector(selectedPlanSelector) || getJsonFomStorage('selectedPlan');
  const dispatch = useDispatch();
  const email = useSelector(userEmailSelector);
  const { t } = useTranslation();
  const userCurrency = useSelector(userCurrencySelector);
  const basePath = useBasePath();

  useEffect(() => {
    dispatch(sendAnalyticEvent({
      event: 'payment_screen_view',
      data: { screen_config_name: 'payment_modal' },
    }));
  }, [dispatch]);

  usePaypalScript({
    paypalScript,
    signature: merchantData?.signature,
  });

  useEffect(() => {
    dispatch(sendAnalyticEvent({
      event: 'payment_card_init_start',
      data: {
        selectedPlanId: selectedPlan?.id ?? '',
      },
    }));

    dispatch(
      initPaymentCard(selectedPlan?.id, (res: any) => {
        dispatch(sendAnalyticEvent({
          event: 'payment_card_init_end',
          data: {
            merchant: res?.merchant || '',
            signature: res?.signature || '',
            paymentIntent: res?.paymentIntent || '',
            productsId: selectedPlan?.id || '',
          },
        }));

        setMerchantData({
          merchant: res?.merchant,
          signature: res?.signature,
          paymentIntent: res?.paymentIntent,
          productsId: selectedPlan?.id,
        });
      })
    );

    dispatch(sendAnalyticEvent({
      event: 'payment_paypal_init_start',
      data: {
        productId: selectedPlan?.id as string,
        customerEmail: (email as string) || (localStorage.getItem('email') as string),
        paymentMethod: PaymentMethod.Paypal,
      },
    }));

    // get paypal payment url
    dispatch(
      initAlternativeSubscription({
        productId: selectedPlan?.id as string,
        customerEmail: (email as string) || (localStorage.getItem('email') as string),
        paymentMethod: PaymentMethod.Paypal,
        onSuccess: (res: any) => {
          dispatch(sendAnalyticEvent({
            event: 'payment_paypal_init_end',
            data: {
              paypalUrl: res.url || '',
              userEmail: email || '',
              orderId: res.orderId || '',
            },
          }));

          if (!localStorage.getItem('email') && email) localStorage.setItem('email', email);
          setPaypalScript(res.url);
          localStorage.setItem('orderId', res.orderId);
        },
      })
    );
  }, [dispatch]); // eslint-disable-line

  const handleTryAgain = () => {
    setMerchantData(null);
    setPaypalScript(null);

    dispatch(
      initPaymentCard(selectedPlan?.id, (res: any) => {
        setMerchantData({
          merchant: res?.merchant,
          signature: res?.signature,
          paymentIntent: res?.paymentIntent,
          productsId: selectedPlan?.id,
        });
      })
    );

    // get paypal payment url
    dispatch(
      initAlternativeSubscription({
        productId: selectedPlan?.id as string,
        customerEmail: (email as string) || (localStorage.getItem('email') as string),
        paymentMethod: PaymentMethod.Paypal,
        onSuccess: (res: any) => {
          if (!localStorage.getItem('email') && email) localStorage.setItem('email', email);
          setPaypalScript(res.url);
          localStorage.setItem('orderId', res.orderId);
        },
      })
    );
  };

  const handleSubTermClick = () => {
    dispatch(sendAnalyticEvent({ event: 'subscription_terms_tap' }));
  };

  return (
    <>
      <GoBackButton
        onClick={() => {
          dispatch(sendAnalyticEvent({ event: 'back_to_select_plan_tap' }));
          dispatch(toggleModal({ visible: true, type: EModalTypes.MODAL_SELECT_PLAN }));
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3438 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2813 11.0312 17.2813C11.2187 17.2813 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2188 18.2812 9.78125 18 9.5Z" />
        </svg>
        {t('checkout_page.back_to_select_plan')}
      </GoBackButton>
      <Container>
        {merchantData?.signature && (
          <PaymentForm
            merchantData={merchantData}
            handleTryAgain={handleTryAgain}
            paypalScript={paypalScript}
          />
        )}
        <SelectedPlanData>
          <SelectedPlanName>
            <span>{t(selectedPlan?.testBillingName || '')} </span>
            <span>{t(selectedPlan?.testName || '')}</span>
          </SelectedPlanName>
          <PriceContainer>
            <Price>
              {selectedPlan?.currencySymbol || userCurrency}
              {selectedPlan.price}
            </Price>
          </PriceContainer>
          <FeaturesContainer>
            {selectedPlan?.testFeatures.map((feature: any, index: number) => (
              <FeatureItem key={`plan-feature-${index + 1}`}>
                <FeatureIcon src={checkMarkIcon} />
                {t(feature)}
              </FeatureItem>
            ))}
          </FeaturesContainer>
          <AdditionalPaymentInfo>
            <NortonContainer>
              <LazyLoadImage src={LockedIcon} alt="locked-icon" />
              <span>{t('checkout_page.your_payment_secured')}</span>
            </NortonContainer>

            <Description>
              {t(selectedPlan?.descriptionPayment, {
                currencySymbol: selectedPlan?.currencySymbol,
                fullPrice: selectedPlan?.fullPrice,
              })}
              {` `}
              <a
                target="_blank"
                onClick={handleSubTermClick}
                href={`${process.env.REACT_APP_DASHBOARD_URL}${basePath}/subscription-terms`}
                rel="noreferrer"
              >
                {t('global.subscription_terms')}
              </a>
              .
            </Description>
          </AdditionalPaymentInfo>
        </SelectedPlanData>
      </Container>
    </>
  );
};

export default PaymentFormTestB;
