export const countPdfPages = async (file: File): Promise<number> => {
  return new Promise((resolve) => {
    if (!file) return resolve(1);

    const reader: any = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g)?.length;
      resolve(count);
    };
  });
};
