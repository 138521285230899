import { FC, memo } from 'react';

import errorIconPath from 'assets/error-icon.svg';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SuggestionMessage } from '../inputPassword/styles';
import { ErrorMessage, InputComponent, InputLabel } from './styles';

interface CommonInputDTO {
  onChange: unknown;
  value: string;
  placeholder?: string;
  label?: string;
  error?: string;
  suggestion?: string;
  type?: string;
  name?: string;
}

const CommonInput: FC<CommonInputDTO> = ({
  label,
  value,
  placeholder,
  error,
  onChange,
  type = '',
  name = '',
  suggestion,
}) => {
  const handleChange = (value: string) => {
    if (typeof onChange === 'function') onChange(value);
  };

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}

      <InputComponent
        name={name}
        type={type}
        placeholder={placeholder || ''}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        addonAfter={error && <LazyLoadImage src={errorIconPath} alt="Error icon" />}
        $errorMessage={error}
      />
      {suggestion && <SuggestionMessage $errorMessage={error}>{suggestion}</SuggestionMessage>}
      {!suggestion && error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default memo(CommonInput);
