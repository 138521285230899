import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: {} = {};

const analyticsReducer = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case EReduxTypes.SEND_ANALYTIC_EVENT: {
      return {
        lastAnalyticEvent: action.analyticEvent?.event
      };
    }
    default:
      return state;
  }
};

export default analyticsReducer;