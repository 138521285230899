import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  height: 151px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8ecff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

export const Container = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 420px;
  min-height: 495px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  padding: 20px 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;
