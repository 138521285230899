import closeImagePath from 'assets/close-icon.svg';
import SelectPlanModalContent from 'components/pricing/abc_modal_vs_united_payment_2_0_7';
import { toggleModal } from 'data/actions/modals';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CloseIcon, ModalContainer } from '../baseModal/styles';
import { Container } from './styles';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import { sendAnalyticEvent } from 'data/actions/analytics';

const StyledModalContainer = styled(Container)`
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(244, 246, 255, 1) 55%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(244, 246, 255, 1) 55%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(244, 246, 255, 1) 55%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f4f6ff",GradientType=1);
`;

const SelectPlanModal = () => {
  const dispatch = useDispatch();
  useCloseModalOnBack();

  const handleCloseModal = useCallback(() => {
    dispatch(sendAnalyticEvent({ event: 'select_plan_close_tap' }));
    dispatch(toggleModal({ visible: false }));
  }, [dispatch]);

  return (
    <ModalContainer>
      <StyledModalContainer>
        <CloseIcon onClick={handleCloseModal} src={closeImagePath} alt="" />
        <SelectPlanModalContent />
      </StyledModalContainer>
    </ModalContainer>
  );
};

export default SelectPlanModal;
